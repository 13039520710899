import { useMediaQuery } from "react-responsive";
import TankerSeriesMobile from "./TankerSeriesMobile";
import TankerSeriesDesktop from "./TankerSeriesDesktop";

const TankerSeries = () => {
  const isMobile = useMediaQuery({ maxWidth: 768 });

  return isMobile ? <TankerSeriesMobile /> : <TankerSeriesDesktop />;
};

export default TankerSeries;
