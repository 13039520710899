import React, { useEffect, useState, useRef } from "react";
import siloImg from "../../assets/images/home/silo.jpg";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { useTranslation } from "react-i18next";
import "../../i18n";
import siloMov from "../../assets/images/SiloSeries/SiloSeries.mov";

import { FaChevronDown, FaChevronUp, FaQuestionCircle } from "react-icons/fa";
import { Tooltip } from "react-tooltip";
import tipping1 from "../../assets/images/SiloSeries/tipping/1tipping.jpg";
import tipping2 from "../../assets/images/SiloSeries/tipping/2tipping.jpg";
import tipping3 from "../../assets/images/SiloSeries/tipping/3tipping.jpg";
import nontipping1 from "../../assets/images/SiloSeries/nontipping/1nontip.jpg";
import nontipping2 from "../../assets/images/SiloSeries/nontipping/2nontip.jpg";

// Non Tipping Silo
import { ReactComponent as Silo30_2_Technical } from "../../assets/images/SiloSeries/nontipping/30.2/SiloSeries-01.svg";
import { ReactComponent as Silo30_2_Measurements } from "../../assets/images/SiloSeries/nontipping/30.2/SiloSeries-11.svg";

import { ReactComponent as Silo35_2_Technical } from "../../assets/images/SiloSeries/nontipping/35.2/SiloSeries-02.svg";
import { ReactComponent as Silo35_2_Measurements } from "../../assets/images/SiloSeries/nontipping/35.2/SiloSeries-12.svg";

import { ReactComponent as Silo39_2_Technical } from "../../assets/images/SiloSeries/nontipping/39.2/SiloSeries-03.svg";
import { ReactComponent as Silo39_2_Measurements } from "../../assets/images/SiloSeries/nontipping/39.2/SiloSeries-13.svg";

import { ReactComponent as Silo40_2_Technical } from "../../assets/images/SiloSeries/nontipping/40.2/SiloSeries-04.svg";
import { ReactComponent as Silo40_2_Measurements } from "../../assets/images/SiloSeries/nontipping/40.2/SiloSeries-14.svg";

import { ReactComponent as Silo40_3_Technical } from "../../assets/images/SiloSeries/nontipping/40.3/SiloSeries-05.svg";
import { ReactComponent as Silo40_3_Measurements } from "../../assets/images/SiloSeries/nontipping/40.3/SiloSeries-15.svg";

import { ReactComponent as Silo45_3_Technical } from "../../assets/images/SiloSeries/nontipping/45.3/SiloSeries-06.svg";
import { ReactComponent as Silo45_3_Measurements } from "../../assets/images/SiloSeries/nontipping/45.3/SiloSeries-16.svg";

import { ReactComponent as Silo48_3_Technical } from "../../assets/images/SiloSeries/nontipping/48.3/SiloSeries-07.svg";
import { ReactComponent as Silo48_3_Measurements } from "../../assets/images/SiloSeries/nontipping/48.3/SiloSeries-17.svg";

import { ReactComponent as Silo52_4_Technical } from "../../assets/images/SiloSeries/nontipping/52.4/SiloSeries-08.svg";
import { ReactComponent as Silo52_4_Measurements } from "../../assets/images/SiloSeries/nontipping/52.4/SiloSeries-18.svg";

import { ReactComponent as Silo55_4_Technical } from "../../assets/images/SiloSeries/nontipping/55.4/SiloSeries-09.svg";
import { ReactComponent as Silo55_4_Measurements } from "../../assets/images/SiloSeries/nontipping/55.4/SiloSeries-19.svg";

import { ReactComponent as Silo60_5_Technical } from "../../assets/images/SiloSeries/nontipping/60.5/SiloSeries-10.svg";
import { ReactComponent as Silo60_5_Measurements } from "../../assets/images/SiloSeries/nontipping/60.5/SiloSeries-20.svg";

// Tipping Silo
import { ReactComponent as Silo45_Technical } from "../../assets/images/SiloSeries/tipping/45/SiloSeries-21.svg";
import { ReactComponent as Silo45_Measurements } from "../../assets/images/SiloSeries/tipping/45/SiloSeries-24.svg";

import { ReactComponent as Silo60_Technical } from "../../assets/images/SiloSeries/tipping/60/SiloSeries-22.svg";
import { ReactComponent as Silo60_Measurements } from "../../assets/images/SiloSeries/tipping/60/SiloSeries-25.svg";

import { ReactComponent as Silo66_Technical } from "../../assets/images/SiloSeries/tipping/66/SiloSeries-23.svg";
import { ReactComponent as Silo66_Measurements } from "../../assets/images/SiloSeries/tipping/66/SiloSeries-26.svg";

function TippingSiloTrailerMobile() {
  const [activeSlider, setActiveSlider] = useState("tipping");
  const [activeIndex, setActiveIndex] = useState(0);

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    arrows: false,
    beforeChange: (oldIndex, newIndex) => setActiveIndex(newIndex),
    customPaging: (i) => (
      <div
        style={{
          margin: "12px",
          width: "12px",
          height: "12px",
          backgroundColor: i === activeIndex ? "gray" : "white",
          borderRadius: "50%",
        }}
      />
    ),
  };
  const products = [
    {
      category: "NON TIPPING SILO",
      codes: [
        {
          id: "30.2",
          technical: Silo30_2_Technical,
          measurements: Silo30_2_Measurements,
        },
        {
          id: "35.2",
          technical: Silo35_2_Technical,
          measurements: Silo35_2_Measurements,
        },
        {
          id: "39.2",
          technical: Silo39_2_Technical,
          measurements: Silo39_2_Measurements,
        },
        {
          id: "40.2",
          technical: Silo40_2_Technical,
          measurements: Silo40_2_Measurements,
        },
        {
          id: "40.3",
          technical: Silo40_3_Technical,
          measurements: Silo40_3_Measurements,
        },
        {
          id: "45.3",
          technical: Silo45_3_Technical,
          measurements: Silo45_3_Measurements,
        },
        {
          id: "48.3",
          technical: Silo48_3_Technical,
          measurements: Silo48_3_Measurements,
        },
        {
          id: "52.4",
          technical: Silo52_4_Technical,
          measurements: Silo52_4_Measurements,
        },
        {
          id: "55.4",
          technical: Silo55_4_Technical,
          measurements: Silo55_4_Measurements,
        },
        {
          id: "60.5",
          technical: Silo60_5_Technical,
          measurements: Silo60_5_Measurements,
        },
      ],
    },
    {
      category: "TIPPING SILO",
      codes: [
        {
          id: "45",
          technical: Silo45_Technical,
          measurements: Silo45_Measurements,
        },
        {
          id: "60",
          technical: Silo60_Technical,
          measurements: Silo60_Measurements,
        },
        {
          id: "66",
          technical: Silo66_Technical,
          measurements: Silo66_Measurements,
        },
      ],
    },
  ];

  const [activeCategory, setActiveCategory] = useState("NON TIPPING SILO");
  const [selectedProduct, setSelectedProduct] = useState("");
  const [activeTab, setActiveTab] = useState("technical");
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const selectedProductData =
    products
      .flatMap((group) => group.codes)
      .find((p) => p.id === selectedProduct) || {};

  return (
    <div className="responsive-product-page">
      <div className="product-banner-container">
        <div className="relative w-full h-[90vh] bg-black overflow-hidden">
          <video
            className="background-video w-full h-full object-cover"
            src={siloMov}
            autoPlay
            loop
            muted
            playsInline
          />
          <div className="scroll-indicator-alt">
            <div className="mouse-icon-alt"></div>
            <p>Scroll Down</p>
          </div>
          <div className="absolute inset-0">
            <div className="absolute top-0 left-0 w-full h-1/4 bg-gradient-to-b from-black to-transparent"></div>
            <div className="absolute bottom-0 left-0 w-full h-1/4 bg-gradient-to-t from-black to-transparent"></div>
          </div>

          <div className="banner-header relative z-10 text-white">
            <h1 className="md:text-5xl lg:text-6xl sm:text-3xl mt-12">
              Silo Trailer Series
            </h1>
          </div>
        </div>
      </div>
      <div className="product-section-mobile">
        <div className="text-container-mobile">
          <h4
            className={activeSlider === "tipping" ? "active" : ""}
            onClick={() => setActiveSlider("tipping")}
          >
            Tipping Silo
          </h4>
          <h4
            className={activeSlider === "nonTipping" ? "active" : ""}
            onClick={() => setActiveSlider("nonTipping")}
          >
            Non Tipping Silo
          </h4>
        </div>
        <div className="slider-container-mobile">
          <Slider {...sliderSettings}>
            {activeSlider === "tipping"
              ? [tipping1, tipping2, tipping3].map((img, idx) => (
                  <div key={idx} className="slider-item-mobile">
                    <img src={img} alt="Tipping Silo" />
                  </div>
                ))
              : [nontipping1, nontipping2].map((img, idx) => (
                  <div key={idx} className="slider-item-mobile">
                    <img src={img} alt="Non Tipping Silo" />
                  </div>
                ))}
          </Slider>
          <div className="text-content-mobile">
            {activeSlider === "tipping" ? (
              <>
                <h2>Where Power Meets Performance</h2>
                <p>
                  With a 45-degree tipping angle, fluidisers and vibrators, our
                  tipping silo trailers ensure fast and complete unloading.
                </p>
              </>
            ) : (
              <>
                <h2>Light. Durable. Reliable.</h2>
                <p>
                  With a 45-degree funnel angle, our trailers ensure fast and
                  complete unloading with minimal maintenance.
                </p>
              </>
            )}
          </div>
        </div>
      </div>
      <div className="product-codes-mobile">
        <div className="category-selector-mobile">
          <button
            className={activeCategory === "NON TIPPING SILO" ? "active" : ""}
            onClick={() => setActiveCategory("NON TIPPING SILO")}
          >
            Non Tipping Silo
          </button>
          <button
            className={activeCategory === "TIPPING SILO" ? "active" : ""}
            onClick={() => setActiveCategory("TIPPING SILO")}
          >
            Tipping Silo
          </button>
        </div>

        <div className="dropdown-container-mobile">
          <button
            className="dropdown-btn-mobile"
            onClick={() => setDropdownOpen(!dropdownOpen)}
          >
            {selectedProduct || "Select a Product"} <FaChevronDown />
          </button>
          {dropdownOpen && (
            <ul className="dropdown-menu-mobile">
              {products
                .find((group) => group.category === activeCategory)
                ?.codes.map((code) => (
                  <li
                    key={code.id}
                    onClick={() => {
                      setSelectedProduct(code.id);
                      setDropdownOpen(false);
                    }}
                  >
                    {code.id}
                  </li>
                ))}
            </ul>
          )}
        </div>

        {selectedProduct && (
          <div className="product-details-mobile">
            <div className="tabs-mobile">
              <h3
                className={activeTab === "technical" ? "active" : ""}
                onClick={() => setActiveTab("technical")}
              >
                Technical Parameters
              </h3>
              <h3
                className={activeTab === "measurements" ? "active" : ""}
                onClick={() => setActiveTab("measurements")}
              >
                Measurements
              </h3>
            </div>
            <div className="product-images-mobile">
              {activeTab === "technical" ? (
                <selectedProductData.technical
                  alt="Technical Parameters"
                  className="my-svg"
                />
              ) : (
                <selectedProductData.measurements
                  alt="Measurements"
                  className="my-svg"
                />
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default TippingSiloTrailerMobile;
