import React from "react";
import { useMediaQuery } from "react-responsive";
import FooterDesktop from "./FooterDesktop";
import FooterMobile from "./FooterMobile";

const Footer = () => {
  const isMobile = useMediaQuery({ maxWidth: 768 });
  return isMobile ? <FooterMobile /> : <FooterDesktop />;
};

export default Footer;
