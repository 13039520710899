import { useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import truckImg from "../../assets/images/home/truck2.jpg";
import truck1 from "../../assets/images/truck/truck1.jpg";
import truck2 from "../../assets/images/truck/truck2.jpg";

import { ReactComponent as Technical } from "../../assets/images/truck/1truck.svg";
import { ReactComponent as Measurement } from "../../assets/images/truck/2truck.svg";

import { FaChevronDown } from "react-icons/fa";

function TruckMountedSeriesMobile() {
  const [activeIndex, setActiveIndex] = useState(0);

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    arrows: false,
    beforeChange: (oldIndex, newIndex) => setActiveIndex(newIndex),
    customPaging: (i) => (
      <div
        style={{
          margin: "12px",
          width: "12px",
          height: "12px",
          backgroundColor: i === activeIndex ? "gray" : "white",
          borderRadius: "50%",
        }}
      />
    ),
  };

  const [activeTab, setActiveTab] = useState("technical");

  return (
    <div className="truck-mounted-mobile">
      <div className="product-banner-container">
        <div className="relative w-full h-[90vh] bg-black overflow-hidden">
          <img
            src={truckImg}
            alt="truck"
            className="w-full h-[90vh] object-cover"
          />
          <div className="scroll-indicator-alt">
            <div className="mouse-icon-alt"></div>
            <p>Scroll Down</p>
          </div>
          <div className="absolute inset-0">
            <div className="absolute top-0 left-0 w-full h-1/4 bg-gradient-to-b from-black to-transparent"></div>
            <div className="absolute bottom-0 left-0 w-full h-1/4 bg-gradient-to-t from-black to-transparent"></div>
          </div>

          <div className="banner-header relative z-10 text-white text-center">
            <h1 className="md:text-5xl lg:text-6xl sm:text-3xl mt-12">
              Truck Mounted Series
            </h1>
          </div>
        </div>
      </div>
      <div className="slider-container-mobile">
        <Slider {...sliderSettings}>
          {[truck1, truck2].map((img, idx) => (
            <div key={idx} className="slider-item-mobile">
              <img src={img} alt={`Truck ${idx + 1}`} />
            </div>
          ))}
        </Slider>
      </div>
      <div className="text-section-mobile">
        <h2>Agile by Design</h2>
        <p>
          Our truck-mounted units offer a low tare weight and great
          maneuverability. Their integrated design minimizes downtime and
          reduces maintenance, ensuring seamless operation in demanding
          environments.
        </p>
      </div>

      <div className="product-details-mobile">
        <div className="tabs-mobile">
          <h3
            className={activeTab === "technical" ? "active" : ""}
            onClick={() => setActiveTab("technical")}
          >
            Technical Parameters
          </h3>
          <h3
            className={activeTab === "measurements" ? "active" : ""}
            onClick={() => setActiveTab("measurements")}
          >
            Measurements
          </h3>
        </div>
        <div className="product-images-mobile">
          {activeTab === "technical" ? (
            <Technical alt="Technical Parameters" className="my-svg" />
          ) : (
            <Measurement alt="Measurements" className="my-svg" />
          )}
        </div>
      </div>
    </div>
  );
}

export default TruckMountedSeriesMobile;
