import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Menu, X } from "lucide-react";
import LanguageDropdown from "./LanguageDropdown";
import logo from "../../assets/images/navbar/winton-logo.png";

const MobileNavbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [expandedSection, setExpandedSection] = useState(null);
  const navigate = useNavigate();

  const toggleMenu = () => setIsMenuOpen(!isMenuOpen);
  const toggleSection = (section) => {
    setExpandedSection(expandedSection === section ? null : section);
  };

  const handleNavigation = (path) => {
    navigate(path);
    setIsMenuOpen(false);
  };

  return (
    <nav className="mobile-navbar">
      <Link to="/" className="logo mt-2">
        <img src={logo} alt="Winton Logo" />
      </Link>

      <button onClick={toggleMenu} className="menu-btn">
        {isMenuOpen ? <X className="icon" /> : <Menu className="icon" />}
      </button>

      {isMenuOpen && (
        <div className="menu-overlay">
          <button onClick={toggleMenu} className="close-btn">
            <X className="icon" />
          </button>

          <button onClick={() => handleNavigation("/")} className="nav-link">
            Home
          </button>

          <div
            className={`nav-section ${
              expandedSection === "vehicles" ? "expanded" : ""
            }`}
          >
            <button
              className="nav-title"
              onClick={() => toggleSection("vehicles")}
            >
              Vehicles
            </button>
            {expandedSection === "vehicles" && (
              <div className="nav-submenu">
                <button
                  onClick={() => handleNavigation("/silo-trailer-series")}
                  className="nav-item"
                >
                  Silo Trailers
                </button>
                <button
                  onClick={() => handleNavigation("/tanker-series")}
                  className="nav-item"
                >
                  Tankers
                </button>
                <button
                  onClick={() => handleNavigation("/container-series")}
                  className="nav-item"
                >
                  Containers
                </button>
                <button
                  onClick={() => handleNavigation("/truck-mounted-series")}
                  className="nav-item"
                >
                  Truck Mounted
                </button>
              </div>
            )}
          </div>

          <div
            className={`nav-section ${
              expandedSection === "discover" ? "expanded" : ""
            }`}
          >
            <button
              className="nav-title"
              onClick={() => toggleSection("discover")}
            >
              Discover
            </button>
            {expandedSection === "discover" && (
              <div className="nav-submenu">
                <button
                  onClick={() => handleNavigation("/about-us")}
                  className="nav-item"
                >
                  About Us
                </button>
                <button
                  onClick={() => handleNavigation("/contact-us")}
                  className="nav-item"
                >
                  Contact Us
                </button>
                <button
                  onClick={() => handleNavigation("/network")}
                  className="nav-item"
                >
                  Network
                </button>
                <button
                  onClick={() => handleNavigation("/download-center")}
                  className="nav-item"
                >
                  Download Center
                </button>
              </div>
            )}
          </div>

          <div
            className={`nav-section ${
              expandedSection === "contact" ? "expanded" : ""
            }`}
          >
            <button
              className="nav-title"
              onClick={() => toggleSection("contact")}
            >
              Contact
            </button>
            {expandedSection === "contact" && (
              <div className="nav-submenu">
                <p className="contact-info">info@wintonindustries.com</p>
                <p className="contact-info">+90 312 815 15 40</p>
              </div>
            )}
          </div>
        </div>
      )}
    </nav>
  );
};

export default MobileNavbar;
