import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

type SliderProps = {
  images: string[];
  title: string;
  description: string;
};

const CustomSlider: React.FC<SliderProps> = ({ images, title, description }) => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 2000,
    pauseOnHover: false,
  };

  return (
    <div className="w-full flex flex-col items-center mt-12">
      {/* SLIDER */}
      <div className="w-full max-w-5xl h-[550px] overflow-hidden rounded-xl shadow-lg">
        <Slider {...settings}>
          {images.map((img, index) => (
            <div key={index}>
              <img
                src={img}
                alt={`slider-${index}`}
                className="w-full h-[550px] object-cover"
              />
            </div>
          ))}
        </Slider>
      </div>

      {/* TEXT CONTENT */}
      <div className="w-full max-w-5xl mt-8 flex flex-col md:flex-row justify-between  px-4 gap-6">
        <h2 className="text-3xl md:w-1/2 text-left text-white">{title}</h2>
        <p className="text-m md:w-1/2 text-left text-white">{description}</p>
      </div>
    </div>
  );
};

export default CustomSlider;