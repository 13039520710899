import React, { useState } from "react";
import { Globe } from "lucide-react";
import { useTranslation } from "react-i18next";

const LanguageDropdown = () => {
  const { t, i18n } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => setIsOpen(!isOpen);

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    localStorage.setItem("language", lng);
    setIsOpen(false);
  };

  return (
    <div className="relative flex justify-center items-center">
      <button
        onClick={toggleDropdown}
        className="p-2 mr-6 mt-2 bg-transparent hover:bg-gray-100 transition"
      >
        <Globe className="w-6 h-6 text-black" />{" "}
        {/* Beyaz renk için text-white */}
      </button>

      {isOpen && (
        <div className="absolute top-10 right-0 w-32 bg-white shadow-lg rounded-lg p-2 mr-6">
          <button
            onClick={() => changeLanguage("tr")}
            className="w-full text-left px-4 py-2 hover:bg-gray-100 flex items-center text-black"
          >
            TR
          </button>
          <button
            onClick={() => changeLanguage("en")}
            className="w-full text-left px-4 py-2 hover:bg-gray-100 flex items-center text-black"
          >
            EN
          </button>
        </div>
      )}
    </div>
  );
};

export default LanguageDropdown;
