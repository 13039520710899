import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./styles/main.scss";
import Anasayfa from "./pages//Home/Home";
import TankerSeries from "./pages/TankerSeries/TankerSeries";
import AboutUs from "./pages/AboutUs/AboutUs";
import SiloTrailerSeries from "./pages/SiloTrailerSeries/SiloTrailerSeries";
import TruckMountedSeries from "./pages/TruckMountedSeries/TruckMountedSeries";

import ContainerSeries from "./pages/ContainerSeries/ContainerSeries";
import Network from "./pages/Network/Network";

import Navbar from "./components//Navbar/Navbar";
import MobileNavbar from "./components//Navbar/MobileNavbar";

import Footer from "./components/Footer/Footer";
import BackToTopButton from "./components/BackToTopButton";

import ContactUs from "./pages/ContactUs/ContactUs";

import "./i18n"; // i18n.js'yi projeye dahil et
import { useTranslation } from "react-i18next";
//import Footer from "./components/Footer";
import FreeMap from "./pages/Network/FreeMap";

function App() {
  return (
    <Router>
      <div className="App">
        <div className="block md:hidden">
          <MobileNavbar />
        </div>

        {/* Masaüstü Navbar - Sadece büyük ekranlarda görünür */}
        <div className="hidden md:block">
          <Navbar />
        </div>

        <Routes>
          <Route path="/" element={<Anasayfa />} />
          <Route path="/tanker-series" element={<TankerSeries />} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route
            path="/truck-mounted-series"
            element={<TruckMountedSeries />}
          />
          <Route path="/silo-trailer-series" element={<SiloTrailerSeries />} />
          <Route path="/container-series" element={<ContainerSeries />} />
          <Route path="/network" element={<Network />} />
          <Route path="/contact-us" element={<ContactUs />} />
        </Routes>
        <BackToTopButton />
        <Footer />
      </div>
    </Router>
  );
}

export default App;
