import React, { useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FaQuestionCircle } from "react-icons/fa";
import { Tooltip } from "react-tooltip";
import tankerMov from "../../assets/images/TankerSeries/petroleum-banner.webm";

import { ReactComponent as Bitumen_Technical } from "../../assets/images/bitumen/TankerSeries-15.svg";
import { ReactComponent as Bitumen_Measurements } from "../../assets/images/bitumen/TankerSeries-16.svg";

import { ReactComponent as Fuel34_4_Techinal } from "../../assets/images/fuel/34.4/TankerSeries-01.svg";
import { ReactComponent as Fuel34_4_Measurements } from "../../assets/images/fuel/34.4/TankerSeries-08.svg";

import { ReactComponent as Fuel34_5_Techinal } from "../../assets/images/fuel/34.5/TankerSeries-02.svg";
import { ReactComponent as Fuel34_5_Measurements } from "../../assets/images/fuel/34.5/TankerSeries-09.svg";

import { ReactComponent as Fuel34_6_Techinal } from "../../assets/images/fuel/34.6/TankerSeries-03.svg";
import { ReactComponent as Fuel34_6_Measurements } from "../../assets/images/fuel/34.6/TankerSeries-10.svg";

import { ReactComponent as Fuel38_5_Techinal } from "../../assets/images/fuel/38.5/TankerSeries-04.svg";
import { ReactComponent as Fuel38_5_Measurements } from "../../assets/images/fuel/38.5/TankerSeries-11.svg";

import { ReactComponent as Fuel38_6_Techinal } from "../../assets/images/fuel/38.6/TankerSeries-05.svg";
import { ReactComponent as Fuel38_6_Measurements } from "../../assets/images/fuel/38.6/TankerSeries-12.svg";

import { ReactComponent as Fuel38_7_Techinal } from "../../assets/images/fuel/38.7/TankerSeries-06.svg";
import { ReactComponent as Fuel38_7_Measurements } from "../../assets/images/fuel/38.7/TankerSeries-13.svg";

import { ReactComponent as Fuel41_1_Techinal } from "../../assets/images/fuel/41.1/TankerSeries-07.svg";
import { ReactComponent as Fuel41_1_Measurements } from "../../assets/images/fuel/41.1/TankerSeries-14.svg";

import bitumen1 from "../../assets/images/bitumen/1bitumen.jpg";
import bitumen2 from "../../assets/images/bitumen/2bitumen.jpg";
import fuel1 from "../../assets/images/fuel/1fuel.jpg";
import fuel2 from "../../assets/images/fuel/2fuel.jpg";
import fuel3 from "../../assets/images/fuel/3fuel.jpg";

function TankerSeriesDesktop() {
  // Slider için ayarlar
  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
  };
  // Product Code listesi ve her biri için ayrı "Technical" ve "Measurements" bilgileri
  const products = [
    {
      category: "BITUMEN",
      codes: [
        {
          id: "Bitumen",
          technical: Bitumen_Technical,
          measurements: Bitumen_Measurements,
        },
      ],
    },
    {
      category: "FUEL",
      codes: [
        {
          id: "34.4",
          technical: Fuel34_4_Techinal,
          measurements: Fuel34_4_Measurements,
        },
        {
          id: "34.5",
          technical: Fuel34_5_Techinal,
          measurements: Fuel34_5_Measurements,
        },
        {
          id: "34.6",
          technical: Fuel34_6_Techinal,
          measurements: Fuel34_6_Measurements,
        },
        {
          id: "38.5",
          technical: Fuel38_5_Techinal,
          measurements: Fuel38_5_Measurements,
        },
        {
          id: "38.6",
          technical: Fuel38_6_Techinal,
          measurements: Fuel38_6_Measurements,
        },
        {
          id: "38.7",
          technical: Fuel38_7_Techinal,
          measurements: Fuel38_7_Measurements,
        },
        {
          id: "41.1",
          technical: Fuel41_1_Techinal,
          measurements: Fuel41_1_Measurements,
        },
      ],
    },
  ];
  const [activeSlider, setActiveSlider] = useState("bitumen");
  const defaultProduct = products[0]?.codes[0]?.id || "";
  const [selectedProduct, setSelectedProduct] = useState(defaultProduct);
  const [activeTab, setActiveTab] = useState("technical"); // "technical" veya "measurements"
  const [expanded, setExpanded] = useState(false);
  const visibleItems = expanded
    ? products
    : products.map((group) => ({
        ...group,
        codes: group.codes.slice(0, 10), // İlk 5 öğeyi göster
      }));

  const selectedProductData =
    products
      .flatMap((group) => group.codes)
      .find((code) => code.id === selectedProduct) || {};

  const technicalParametersSVG = (
    <svg width="100%" height="900" style={{ background: "black" }}>
      <text x="50%" y="50%" fill="white" textAnchor="middle">
        Technical Parameters (SVG)
      </text>
    </svg>
  );

  const measurementsSVG = (
    <svg width="100%" height="900" style={{ background: "black" }}>
      <text x="50%" y="50%" fill="white" textAnchor="middle">
        Measurements (SVG)
      </text>
    </svg>
  );
  return (
    <div className="product-page">
      {/* Banner Section */}
      <div className="product-banner-container">
        <div className="relative w-full h-[90vh] bg-black overflow-hidden">
          <video
            className="background-video w-full h-full object-cover"
            src={tankerMov}
            autoPlay
            loop
            muted
          />
          <div className="scroll-indicator-alt">
            <div className="mouse-icon-alt"></div>
            <p>Scroll Down</p>
          </div>
          <div className="absolute inset-0">
            <div className="absolute top-0 left-0 w-full h-1/4 bg-gradient-to-b from-black to-transparent"></div>
            <div className="absolute bottom-0 left-0 w-full h-1/4 bg-gradient-to-t from-black to-transparent"></div>
          </div>

          <div className="banner-header relative z-10 text-white">
            <h1 className="md:text-5xl lg:text-6xl sm:text-3xl mt-12">
              Tanker Series
            </h1>
          </div>
        </div>
      </div>
      <div className="impact-resistance-section">
        {/* Sabit Metin */}
        <div className="text-section pt-20 mt-10">
          <h4
            className={
              activeSlider === "bitumen"
                ? "selected-title cursor-pointer"
                : "unselected-title cursor-pointer"
            }
            onClick={() => setActiveSlider("bitumen")}
          >
            Bitumen{" "}
          </h4>
          <h4
            className={
              activeSlider === "fuel"
                ? "selected-title cursor-pointer"
                : "unselected-title cursor-pointer"
            }
            onClick={() => setActiveSlider("fuel")}
          >
            Fuel{" "}
          </h4>
          <br />
          <br />
          {activeSlider === "bitumen" ? (
            <>
              <h2>Built for Endurance </h2>
              <p>
                Our bitumen tankers feature burner and coil heater systems that
                maintain stable temperatures with automatic sensor control.
                Available in stainless and carbon steel, they offer insulation
                optimized for operational weather conditions. With a stainless
                steel mirror finish, chassis options in stainless steel or
                S700MC, and flush or quick gate valve unloading systems, they
                ensure durability and easy maintenance.
              </p>
            </>
          ) : (
            <>
              <h2>Fueled by Precision </h2>
              <p>
                Designed for stability and efficiency, our fuel tank trailers
                feature a low center of gravity when fully loaded and an
                optimized tare weight. Equipped with vapor valves, recovery
                adaptors, overfill sensors, and pneumatic control panels, they
                also offer pump, flow meter, and hose reel options, ensuring
                seamless operation.
              </p>
            </>
          )}
        </div>

        {/* Slider Bölümü */}
        <div className="image-section">
          {activeSlider === "bitumen" ? (
            <Slider {...sliderSettings}>
              <div>
                <img
                  src={bitumen1}
                  alt="Bitumen 1"
                  className="w-full rounded-lg"
                />
              </div>
              <div>
                <img
                  src={bitumen2}
                  alt="Bitumen 2"
                  className="w-full rounded-lg"
                />
              </div>
            </Slider>
          ) : (
            <Slider {...sliderSettings}>
              <div>
                <img src={fuel1} alt="Fuel 1" className="w-full rounded-lg" />
              </div>
              <div>
                <img src={fuel2} alt="Fuel 2" className="w-full rounded-lg" />
              </div>
              <div>
                <img src={fuel3} alt="Fuel 3" className="w-full rounded-lg" />
              </div>
            </Slider>
          )}
        </div>
      </div>
      <div className="product-codes-section bg-black text-white p-8 h-screen flex justify-center items-center">
        {/* İçerik Alanı */}
        <div className="grid grid-cols-3 gap-4 w-full h-auto items-start pl-[4rem]">
          {/* Sol Taraf: Product Codes Listesi */}
          <div className="col-span-1 pl-12 flex flex-col justify-start items-start">
            {/* Üst Başlık */}
            <div className="flex justify-between items-center pb-4 w-full">
              <h2
                className={`font-bold flex items-center transition-all duration-300 ${
                  expanded ? "text-xl" : "text-3xl"
                }`}
              >
                Product Codes
                <FaQuestionCircle
                  data-tooltip-id="info-tooltip"
                  className="ml-2 text-gray-400 cursor-pointer hover:text-gray-200 text-sm"
                />
              </h2>
              <Tooltip id="info-tooltip" place="top" effect="solid">
                Örnek metin
              </Tooltip>
            </div>

            {/* Kategoriler ve Product Codes */}
            {visibleItems.map((group, index) => (
              <div key={index} className="mb-4 w-full">
                <h3
                  className={`font-bold uppercase tracking-tight transition-all duration-300 ${
                    expanded
                      ? "text-base text-gray-400"
                      : "text-lg text-gray-300"
                  }`}
                >
                  {group.category}
                </h3>
                {group.codes.map((code, i) => (
                  <p
                    key={i}
                    className={`cursor-pointer transition-all duration-300 ${
                      selectedProduct === code.id
                        ? "font-bold text-white"
                        : expanded
                        ? "text-sm text-gray-500 hover:text-gray-300"
                        : "text-lg text-gray-500 hover:text-gray-300"
                    }`}
                    onClick={() => setSelectedProduct(code.id)}
                  >
                    W.S/222/{code.id}
                  </p>
                ))}
              </div>
            ))}
          </div>

          {/* Sağ Taraf: Teknik Parametreler ve Measurements İçeriği */}
          <div className="col-span-2 flex flex-col justify-start items-start pl-12">
            {/* Technical Parameters & Measurements Başlıkları */}
            <div
              className="flex justify-start gap-12 w-full mb-4"
              style={{
                marginTop: "0.7rem",
                marginLeft: "0.9rem",
              }}
            >
              <h3
                className={`cursor-pointer text-xl ${
                  activeTab === "technical"
                    ? "text-white"
                    : "text-gray-500 hover:text-gray-300 transition"
                }`}
                onClick={() => setActiveTab("technical")}
              >
                Technical Parameters
              </h3>
              <h3
                className={`cursor-pointer text-xl ${
                  activeTab === "measurements"
                    ? "text-white"
                    : "text-gray-500 hover:text-gray-300 transition"
                }`}
                onClick={() => setActiveTab("measurements")}
              >
                Measurements
              </h3>
            </div>

            {/* SVG İçeriği */}
            <div className="flex justify-start items-start w-full min-h-[500px] max-h-[500px] transition-all duration-500 ease-in-out">
              {activeTab === "technical" ? (
                <selectedProductData.technical
                  alt="Technical Parameters"
                  className="max-w-full max-h-[600px] object-contain my-svg"
                />
              ) : (
                <selectedProductData.measurements
                  alt="Measurements"
                  className="max-w-full max-h-[600px] object-contain my-svg"
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TankerSeriesDesktop;
