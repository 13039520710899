import { useRef, useState } from 'react';
import emailjs from '@emailjs/browser';

const ContactForm = () => {
  const form = useRef();
  const [loading, setLoading] = useState(false);
  const [selectedTopic, setSelectedTopic] = useState('');
  const [cvFile, setCvFile] = useState(null);
  const [isChecked, setIsChecked] = useState({
    privacy: false,
    marketing: false,
    withdraw: false,
  });

  const handleCheckboxChange = (e) => {
    setIsChecked({
      ...isChecked,
      [e.target.name]: e.target.checked,
    });
  };

  const handleTopicChange = (e) => {
    setSelectedTopic(e.target.value);
  };

  const handleFileChange = (e) => {
    setCvFile(e.target.files[0]);
  };

  const allChecked = Object.values(isChecked).every(Boolean);

  const sendEmail = (e) => {
    e.preventDefault();
    if (!allChecked) {
      alert('Please check all boxes.');
      return;
    }

    setLoading(true);
    
    const serviceID = 'service_sa0mq0q';
    const templateID = 'template_2rg6vf8';
    
    const formData = new FormData(form.current);
    if (cvFile) {
      formData.append('cv_file', cvFile);
    }

    emailjs.sendForm(serviceID, templateID, formData, '7U1kb0KNXhqAktxG2')
      .then(
        () => {
          alert('Message successfully sent!');
          form.current.reset();
          setIsChecked({ privacy: false, marketing: false, withdraw: false });
          setSelectedTopic('');
          setCvFile(null);
          setLoading(false);
        },
        () => {
          alert('Failed to send message, please try again.');
          setLoading(false);
        }
      );
  };

  return (
    <div className="form-container">
      <p className="required">* Required fields</p>
      <form ref={form} onSubmit={sendEmail}>
        <select name="topic" required onChange={handleTopicChange}>
          <option value="">Choose a topic *</option>
          <option>General Inquiry</option>
          <option>Sales & Orders</option>
          <option>Technical Support</option>
          <option>Spare Parts & Maintenance</option>
          <option>Logistics & Delivery</option>
          <option>HR & Career Opportunities</option>
        </select>

        <div className="form-row">
          <input name="first_name" placeholder="First Name*" required />
          <input name="last_name" placeholder="Last Name*" required />
        </div>

        <div className="form-row">
          <input type="email" name="email" placeholder="E-Mail*" required />
          <input type="tel" name="phone" placeholder="Phone Number* Ex: +49 0800 527726473" />
        </div>


        <textarea name="message" placeholder="Your Message*" required />

        <div className="form-row">
          <input name="company" placeholder="Company Name*" required />
          <input name="position" placeholder="Position*" required />
        </div>
        
        {selectedTopic === 'HR & Career Opportunities' && (
          <div className="cv-upload-container">
            <label className="cv-label">Upload your CV (PDF only):</label>
            <div className="cv-upload-box">
              <input 
                type="file" 
                name="cv_file" 
                accept="application/pdf" 
                onChange={handleFileChange} 
                required
                className="cv-input"
              />
              {cvFile && <p className="cv-file-name">{cvFile.name}</p>}
            </div>
          </div>
        )}

        <div className="checkbox-group">
          <label>
            <input type="checkbox" name="privacy" checked={isChecked.privacy} onChange={handleCheckboxChange} required />
            I agree in processing the entered data for the reason to contact me to fulfil my request.*
          </label>

          <label>
            <input type="checkbox" name="marketing" checked={isChecked.marketing} onChange={handleCheckboxChange} />
            I agree in processing the entered data for the reason to contact me for general marketing purposes.
          </label>

          <label>
            <input type="checkbox" name="withdraw" checked={isChecked.withdraw} onChange={handleCheckboxChange} />
            I know I can withdraw anytime by sending an email to info@example.com.
          </label>
        </div>

        <p className="privacy-policy">
          By clicking send I agree to the <a href="#">Privacy Policy.</a>
        </p>

        <button  
        type="submit" 
        disabled={!allChecked || loading} 
        style={{ backgroundColor: "#FF3E30", color: "#fff", padding: "10px 15px", border: "none", borderRadius: "5px", cursor: "pointer", opacity: (!allChecked || loading) ? 0.6 : 1 }}
      >
        {loading ? 'Sending...' : 'SEND'}
      </button>
      </form>
    </div>
  );
};

export default ContactForm;
