import React, { useEffect, useState, useRef } from "react";
import siloImg from "../../assets/images/home/silo.jpg";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { useTranslation } from "react-i18next";
import "../../i18n";
import siloMov from "../../assets/images/SiloSeries/SiloSeries.mov";

import { FaChevronDown, FaChevronUp, FaQuestionCircle } from "react-icons/fa";
import { Tooltip } from "react-tooltip";
import tipping1 from "../../assets/images/SiloSeries/tipping/1tipping.jpg";
import tipping2 from "../../assets/images/SiloSeries/tipping/2tipping.jpg";
import tipping3 from "../../assets/images/SiloSeries/tipping/3tipping.jpg";
import nontipping1 from "../../assets/images/SiloSeries/nontipping/1nontip.jpg";
import nontipping2 from "../../assets/images/SiloSeries/nontipping/2nontip.jpg";

// Non Tipping Silo
import { ReactComponent as Silo30_2_Technical } from "../../assets/images/SiloSeries/nontipping/30.2/SiloSeries-01.svg";
import { ReactComponent as Silo30_2_Measurements } from "../../assets/images/SiloSeries/nontipping/30.2/SiloSeries-11.svg";

import { ReactComponent as Silo35_2_Technical } from "../../assets/images/SiloSeries/nontipping/35.2/SiloSeries-02.svg";
import { ReactComponent as Silo35_2_Measurements } from "../../assets/images/SiloSeries/nontipping/35.2/SiloSeries-12.svg";

import { ReactComponent as Silo39_2_Technical } from "../../assets/images/SiloSeries/nontipping/39.2/SiloSeries-03.svg";
import { ReactComponent as Silo39_2_Measurements } from "../../assets/images/SiloSeries/nontipping/39.2/SiloSeries-13.svg";

import { ReactComponent as Silo40_2_Technical } from "../../assets/images/SiloSeries/nontipping/40.2/SiloSeries-04.svg";
import { ReactComponent as Silo40_2_Measurements } from "../../assets/images/SiloSeries/nontipping/40.2/SiloSeries-14.svg";

import { ReactComponent as Silo40_3_Technical } from "../../assets/images/SiloSeries/nontipping/40.3/SiloSeries-05.svg";
import { ReactComponent as Silo40_3_Measurements } from "../../assets/images/SiloSeries/nontipping/40.3/SiloSeries-15.svg";

import { ReactComponent as Silo45_3_Technical } from "../../assets/images/SiloSeries/nontipping/45.3/SiloSeries-06.svg";
import { ReactComponent as Silo45_3_Measurements } from "../../assets/images/SiloSeries/nontipping/45.3/SiloSeries-16.svg";

import { ReactComponent as Silo48_3_Technical } from "../../assets/images/SiloSeries/nontipping/48.3/SiloSeries-07.svg";
import { ReactComponent as Silo48_3_Measurements } from "../../assets/images/SiloSeries/nontipping/48.3/SiloSeries-17.svg";

import { ReactComponent as Silo52_4_Technical } from "../../assets/images/SiloSeries/nontipping/52.4/SiloSeries-08.svg";
import { ReactComponent as Silo52_4_Measurements } from "../../assets/images/SiloSeries/nontipping/52.4/SiloSeries-18.svg";

import { ReactComponent as Silo55_4_Technical } from "../../assets/images/SiloSeries/nontipping/55.4/SiloSeries-09.svg";
import { ReactComponent as Silo55_4_Measurements } from "../../assets/images/SiloSeries/nontipping/55.4/SiloSeries-19.svg";

import { ReactComponent as Silo60_5_Technical } from "../../assets/images/SiloSeries/nontipping/60.5/SiloSeries-10.svg";
import { ReactComponent as Silo60_5_Measurements } from "../../assets/images/SiloSeries/nontipping/60.5/SiloSeries-20.svg";

// Tipping Silo
import { ReactComponent as Silo45_Technical } from "../../assets/images/SiloSeries/tipping/45/SiloSeries-21.svg";
import { ReactComponent as Silo45_Measurements } from "../../assets/images/SiloSeries/tipping/45/SiloSeries-24.svg";

import { ReactComponent as Silo60_Technical } from "../../assets/images/SiloSeries/tipping/60/SiloSeries-22.svg";
import { ReactComponent as Silo60_Measurements } from "../../assets/images/SiloSeries/tipping/60/SiloSeries-25.svg";

import { ReactComponent as Silo66_Technical } from "../../assets/images/SiloSeries/tipping/66/SiloSeries-23.svg";
import { ReactComponent as Silo66_Measurements } from "../../assets/images/SiloSeries/tipping/66/SiloSeries-26.svg";

function TippingSiloTrailerDesktop() {
  // Slider için ayarlar
  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 2000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 6000,
  };
  // Product Code listesi ve her biri için ayrı "Technical" ve "Measurements" bilgileri
  const products = [
    {
      category: "NON TIPPING SILO",
      codes: [
        {
          id: "30.2",
          technical: Silo30_2_Technical,
          measurements: Silo30_2_Measurements,
        },
        {
          id: "35.2",
          technical: Silo35_2_Technical,
          measurements: Silo35_2_Measurements,
        },
        {
          id: "39.2",
          technical: Silo39_2_Technical,
          measurements: Silo39_2_Measurements,
        },
        {
          id: "40.2",
          technical: Silo40_2_Technical,
          measurements: Silo40_2_Measurements,
        },
        {
          id: "40.3",
          technical: Silo40_3_Technical,
          measurements: Silo40_3_Measurements,
        },
        {
          id: "45.3",
          technical: Silo45_3_Technical,
          measurements: Silo45_3_Measurements,
        },
        {
          id: "48.3",
          technical: Silo48_3_Technical,
          measurements: Silo48_3_Measurements,
        },
        {
          id: "52.4",
          technical: Silo52_4_Technical,
          measurements: Silo52_4_Measurements,
        },
        {
          id: "55.4",
          technical: Silo55_4_Technical,
          measurements: Silo55_4_Measurements,
        },
        {
          id: "60.5",
          technical: Silo60_5_Technical,
          measurements: Silo60_5_Measurements,
        },
      ],
    },
    {
      category: "TIPPING SILO",
      codes: [
        {
          id: "45",
          technical: Silo45_Technical,
          measurements: Silo45_Measurements,
        },
        {
          id: "60",
          technical: Silo60_Technical,
          measurements: Silo60_Measurements,
        },
        {
          id: "66",
          technical: Silo66_Technical,
          measurements: Silo66_Measurements,
        },
      ],
    },
  ];
  // Kullanıcının hangi slider'ı görmek istediğini belirleyen state
  const [activeSlider, setActiveSlider] = useState("tipping");
  const defaultProduct = products[0]?.codes[0]?.id || "";
  const [selectedProduct, setSelectedProduct] = useState(defaultProduct);
  const [activeTab, setActiveTab] = useState("technical"); // "technical" veya "measurements"
  const [expanded, setExpanded] = useState(false);
  const visibleItems = expanded
    ? products
    : products.map((group) => ({
        ...group,
        codes: group.codes.slice(0, 5), // İlk 3 öğeyi göster
      }));

  // Seçili product'un verisini getir (Güvenli kullanım)
  const selectedProductData =
    products
      .flatMap((group) => group.codes)
      .find((code) => code.id === selectedProduct) || {};

  const technicalParametersSVG = (
    <svg width="100%" height="900" style={{ background: "black" }}>
      <text x="50%" y="50%" fill="white" textAnchor="middle">
        Technical Parameters (SVG)
      </text>
    </svg>
  );

  // Measurements SVG (placeholder)
  const measurementsSVG = (
    <svg width="100%" height="900" style={{ background: "black" }}>
      <text x="50%" y="50%" fill="white" textAnchor="middle">
        Measurements (SVG)
      </text>
    </svg>
  );
  const { t } = useTranslation(); // `t` fonksiyonunu al

  return (
    <div className="product-page">
      {/* Banner Section */}
      <div className="product-banner-container">
        <div className="relative w-full h-[90vh] bg-black overflow-hidden">
          <video
            className="background-video w-full h-full object-cover"
            src={siloMov}
            autoPlay
            loop
            muted
          />
          <div className="scroll-indicator-alt">
            <div className="mouse-icon-alt"></div>
            <p>Scroll Down</p>
          </div>
          <div className="absolute inset-0">
            <div className="absolute top-0 left-0 w-full h-1/4 bg-gradient-to-b from-black to-transparent"></div>
            <div className="absolute bottom-0 left-0 w-full h-1/4 bg-gradient-to-t from-black to-transparent"></div>
          </div>

          <div className="banner-header relative z-10 text-white">
            <h1 className="md:text-5xl lg:text-6xl sm:text-3xl mt-12">
              Silo Trailer Series
            </h1>
          </div>
        </div>
      </div>

      <div className="impact-resistance-section">
        {/* Sabit Metin */}
        <div className="text-section pt-20 mt-10">
          <h4
            className={
              activeSlider === "tipping"
                ? "selected-title cursor-pointer"
                : "unselected-title cursor-pointer"
            }
            onClick={() => setActiveSlider("tipping")}
          >
            Tipping Silo
          </h4>
          <h4
            className={
              activeSlider === "nonTipping"
                ? "selected-title cursor-pointer"
                : "unselected-title cursor-pointer"
            }
            onClick={() => setActiveSlider("nonTipping")}
          >
            Non Tipping Silo
          </h4>
          <br />
          <br />
          {activeSlider === "tipping" ? (
            <>
              <h2>Where Power Meets Performance </h2>
              <p>
                With a 45-degree tipping angle, fluidisers and vibrators, our
                tipping silo trailers ensure fast and complete unloading. Low
                maintenance discharge components guarantee long-lasting
                performance. The powerpack ensures compatibility with any truck,
                while the aluminum chassis provides a lightweight design. A
                fully automatic hydraulic balancing system streamlines daily
                operations.
              </p>
            </>
          ) : (
            <>
              <h2>Light. Durable. Reliable. </h2>
              <p>
                With a 45-degree funnel angle, vibrator, fluidizers and slide
                fabric, our trailers ensure fast and complete unloading.
                Food-grade electropolished stainless steel discharge funnels and
                equipment ensure full compliance. Together with user-friendly
                valves that require little maintenance, these features ensure
                seamless, reliable performance.
              </p>
            </>
          )}
        </div>

        {/* Slider Bölümü */}
        <div className="image-section">
          {activeSlider === "tipping" ? (
            <Slider {...sliderSettings}>
              <div>
                <img
                  src={tipping1}
                  alt="Tipping Silo 1"
                  className="w-full rounded-lg"
                />
              </div>
              <div>
                <img
                  src={tipping2}
                  alt="Tipping Silo 2"
                  className="w-full rounded-lg"
                />
              </div>
              <div>
                <img
                  src={tipping3}
                  alt="Tipping Silo 3"
                  className="w-full rounded-lg"
                />
              </div>
            </Slider>
          ) : (
            <Slider {...sliderSettings}>
              <div>
                <img
                  src={nontipping1}
                  alt="Non Tipping Silo 1"
                  className="w-full rounded-lg"
                />
              </div>
              <div>
                <img
                  src={nontipping2}
                  alt="Non Tipping Silo 2"
                  className="w-full rounded-lg"
                />
              </div>
            </Slider>
          )}
        </div>
      </div>
      <div className="product-codes-section bg-black text-white p-8 h-screen flex justify-center items-center">
        {/* İçerik Alanı */}
        <div className="grid grid-cols-3 gap-4 w-full h-auto items-start pl-[4rem]">
          {/* Sol Taraf: Product Codes Listesi */}
          <div className="col-span-1 pl-12 flex flex-col justify-start items-start">
            {/* Üst Başlık */}
            <div className="flex justify-between items-center pb-4 w-full">
              <h2
                className={`font-bold flex items-center transition-all duration-300 ${
                  expanded ? "text-xl" : "text-3xl"
                }`}
              >
                Product Codes
                <FaQuestionCircle
                  data-tooltip-id="info-tooltip"
                  className="ml-2 text-gray-400 cursor-pointer hover:text-gray-200 text-sm"
                />
              </h2>
              <Tooltip id="info-tooltip" place="top" effect="solid">
                Örnek metin
              </Tooltip>
            </div>

            {/* Kategoriler ve Product Codes */}
            {visibleItems.map((group, index) => (
              <div key={index} className="mb-4 w-full">
                <h3
                  className={`font-bold uppercase tracking-tight transition-all duration-300 ${
                    expanded
                      ? "text-base text-gray-400"
                      : "text-lg text-gray-300"
                  }`}
                >
                  {group.category}
                </h3>
                {group.codes.map((code, i) => (
                  <p
                    key={i}
                    className={`cursor-pointer transition-all duration-300 ${
                      selectedProduct === code.id
                        ? "font-bold text-white"
                        : expanded
                        ? "text-sm text-gray-500 hover:text-gray-300"
                        : "text-lg text-gray-500 hover:text-gray-300"
                    }`}
                    onClick={() => setSelectedProduct(code.id)}
                  >
                    W.S/222/{code.id}
                  </p>
                ))}
              </div>
            ))}

            {/* See More / See Less Butonu */}
            <div className="flex justify-start">
              <button
                onClick={() => setExpanded(!expanded)}
                className="text-white font-semibold text-sm uppercase flex items-center gap-2 hover:text-gray-300 transition-all duration-300"
              >
                {expanded ? "See Less" : "See More"}
                {expanded ? <FaChevronUp /> : <FaChevronDown />}
              </button>
            </div>
          </div>

          {/* Sağ Taraf: Teknik Parametreler ve Measurements İçeriği */}
          <div className="col-span-2 flex flex-col justify-start items-start pl-12">
            {/* Technical Parameters & Measurements Başlıkları */}
            <div
              className="flex justify-start gap-12 w-full mb-4"
              style={{
                marginTop: "0.7rem",
                marginLeft: "0.9rem",
              }}
            >
              <h3
                className={`cursor-pointer text-xl ${
                  activeTab === "technical"
                    ? "text-white"
                    : "text-gray-500 hover:text-gray-300 transition"
                }`}
                onClick={() => setActiveTab("technical")}
              >
                Technical Parameters
              </h3>
              <h3
                className={`cursor-pointer text-xl ${
                  activeTab === "measurements"
                    ? "text-white "
                    : "text-gray-500 hover:text-gray-300 transition"
                }`}
                onClick={() => setActiveTab("measurements")}
              >
                Measurements
              </h3>
            </div>

            {/* SVG İçeriği */}
            <div className="flex justify-start items-start w-full min-h-[500px] max-h-[500px] transition-all duration-500 ease-in-out">
              {activeTab === "technical" ? (
                <selectedProductData.technical
                  alt="Technical Parameters"
                  className="max-w-full max-h-[600px] object-contain my-svg"
                />
              ) : (
                <selectedProductData.measurements
                  alt="Measurements"
                  className="max-w-full max-h-[600px] object-contain my-svg"
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TippingSiloTrailerDesktop;
