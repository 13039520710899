import { useMediaQuery } from "react-responsive";
import ContactUsMobile from "./ContactUsMobile";
import ContactUsDesktop from "./ContactUsDesktop";

const ContactUs = () => {
  const isMobile = useMediaQuery({ maxWidth: 768 });

  return isMobile ? <ContactUsMobile /> : <ContactUsDesktop />;
};

export default ContactUs;
