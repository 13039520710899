import ContactForm from "../../components//Contact/ContactForm";
import { FaTwitter, FaLinkedin, FaInstagram, FaYoutube } from "react-icons/fa";

function ContactUsDesktop() {
  return (
    <div className="contact-page mt-6">
      <div className="container">
        <div className="left-side mt-6">
          <h1 className="title">GET IN TOUCH</h1>
          <p>
            We are here to assist you with your inquiries.
            <br />
            Please fill out the following contact form or reach out to us
            directly.
          </p>

          <div className="info-block">
            <h4>Customer Support</h4>
            <p className="phone-number">
              +90 312 815 15 40
              <br />
              <a href="mailto:info@wintonindustries.com">
                info@wintonindustries.com
              </a>
              <br />
              Monday through Friday
              <br />
              08:15 - 18:15 (GMT+3)
            </p>

            <h4>Follow Us for Updates</h4>
            <p>
              Stay updated with our latest news, innovations, and events by
              following us on social media.
            </p>
            <div className="social-icons">
              <a
                href="https://www.linkedin.com/company/winton-industries/?viewAsMember=true"
                className="text-red-600"
              >
                <FaLinkedin />
              </a>
              <a
                href="https://www.youtube.com/@wintonindustries4684"
                className="text-red-600"
              >
                <FaYoutube />
              </a>
              <a
                href="https://www.instagram.com/wintonindustries/"
                className="text-red-600"
              >
                <FaInstagram />
              </a>
            </div>
          </div>
        </div>

        <div className="right-side ">
          <ContactForm />
        </div>
      </div>
    </div>
  );
}

export default ContactUsDesktop;
