import i18n from "i18next";
import { initReactI18next } from "react-i18next";

const savedLanguage = localStorage.getItem("language") || "en"; // Kaydedilen dili getir

i18n.use(initReactI18next).init({
  resources: {
    en: {
      translation: {
        scroll_down: "Scroll Down",
        aboutUs: "About Us",
        services: "Services",
      },
    },
    tr: {
      translation: {
        scroll_down: "Aşağı Kaydır",
        aboutUs: "Hakkımızda",
        services: "Hizmetler",
      },
    },
  },
  lng: savedLanguage, // Kaydedilen dili yükle
  fallbackLng: "en",
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
