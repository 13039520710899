import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import tankerImg from "../../assets/images/home/tanker.jpg"; // tanker
import containerImg from "../../assets/images/home/container.jpg"; // container
import siloImg from "../../assets/images/home/silo.jpg"; // silo trailer
import truckImg from "../../assets/images/home/truck.jpg"; // truck mounted
import hVideo from "../../assets/images/home/home.mp4";
import truckImg2 from "../../assets/images/home/truck2.jpg"; //truckmounted

function HomeDesktop() {
  const [activeSection, setActiveSection] = useState(null);
  const sectionsRef = useRef([]);
  const footerRef = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.2,
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setActiveSection(entry.target.id);
        }
      });
    }, options);

    sectionsRef.current.forEach((section) => {
      if (section) observer.observe(section);
    });

    return () => {
      sectionsRef.current.forEach((section) => {
        if (section) observer.unobserve(section);
      });
    };
  }, []);

  // Sayfa en üstten başlaması için yönlendirme fonksiyonu
  const handleNavigation = (path) => {
    navigate(path);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  // **Footer'a scroll fonksiyonu**
  const scrollToFooter = () => {
    if (footerRef.current) {
      footerRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <div className="page">
      <div className="content">
        {/* İlk Section (Video Arka Planlı) */}
        <section
          id="section-1"
          ref={(el) => (sectionsRef.current[0] = el)}
          className={`section first-section relative ${
            activeSection === "section-1" ? "active" : ""
          }`}
        >
          <video
            className="background-video w-full h-full object-cover"
            src={hVideo}
            autoPlay
            loop
            muted
          />
          <div className="scroll-indicator">
            <div className="mouse-icon"></div>
            <p>Scroll Down</p>
          </div>

          <div className="absolute inset-0 pointer-events-none">
            <div className="absolute top-0 left-0 w-full h-1/6 bg-gradient-to-b from-black to-transparent"></div>
            <div className="absolute bottom-0 left-0 w-full h-1/4 bg-gradient-to-t from-black to-transparent"></div>
          </div>
        </section>

        {/* Diğer Bölümler */}
        {[
          {
            id: "section-1",
            image: siloImg,
            title: "Silo Trailer Series",
            path: "/silo-trailer-series",
          },
          {
            id: "section-2",
            image: tankerImg,
            title: "Tanker Series",
            path: "/tanker-series",
          },
          {
            id: "section-3",
            image: containerImg,
            title: "Container Series",
            path: "/container-series",
          },
          {
            id: "section-4",
            image: truckImg2,
            title: "Truck Mounted Series",
            path: "/truck-mounted-series",
          },
        ].map(({ id, image, title, path }, index) => (
          <section
            key={id}
            id={id}
            ref={(el) => (sectionsRef.current[index + 1] = el)}
            className={`section ${activeSection === id ? "active" : ""}`}
            style={{
              backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0) 30%, rgba(0, 0, 0, 0) 70%, rgba(0, 0, 0, 0.5)), url(${image})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          >
            <div className="section-content">
              <div className="section-text">
                <h1 className="section-title">{title}</h1>
              </div>
              <div className="section-buttons">
                <button className="button-primary" onClick={scrollToFooter}>
                  Get in Touch
                </button>
                <button
                  className="button-secondary"
                  onClick={() => handleNavigation(path)}
                >
                  Learn More
                </button>
              </div>
            </div>
          </section>
        ))}
        <footer ref={footerRef}></footer>
      </div>
    </div>
  );
}

export default HomeDesktop;
