import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import LanguageDropdown from "./LanguageDropdown"; // Bileşeni içe aktar

import logo from "../../assets/images/navbar/winton-logo.png";
import redLogo from "../../assets/images/navbar/winton-logo-red.png";

import vehicleLogo1 from "../../assets/images/navbar/petroleum.png";
import vehicleLogo2 from "../../assets/images/navbar/container.png";
import vehicleLogo3 from "../../assets/images/navbar/siloseries.png";
import vehicleLogo4 from "../../assets/images/navbar/truckmounted.png";

function Navbar() {
  const [isVehiclesOpen, setIsVehiclesOpen] = React.useState(false);
  const [isDiscoverOpen, setIsDiscoverOpen] = React.useState(false);

  const handleMouseEnterVehicles = () => setIsVehiclesOpen(true);
  const handleMouseLeaveVehicles = () => setIsVehiclesOpen(false);

  const handleMouseEnterDiscover = () => setIsDiscoverOpen(true);
  const handleMouseLeaveDiscover = () => setIsDiscoverOpen(false);

  const { t, i18n } = useTranslation();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng); // Dili değiştir
    localStorage.setItem("language", lng); // Seçimi kaydet
  };

  return (
    <div className="nav-container">
      <nav
        className={`navbar ${
          isVehiclesOpen || isDiscoverOpen ? "navbar-white" : ""
        }`}
      >
        {/* Logo */}
        <Link to="/" className="navbar-logo">
          <img
            src={isVehiclesOpen || isDiscoverOpen ? redLogo : logo}
            alt="Winton Logo"
          />
        </Link>

        {/* Navbar Links */}
        <ul className="navbar-links">
          {/* Vehicles Dropdown */}
          <li
            className="nav-item vehicles-menu"
            onMouseEnter={handleMouseEnterVehicles}
            onMouseLeave={handleMouseLeaveVehicles}
          >
            <span>Vehicles</span>
          </li>

          {/* Discover Dropdown */}
          <li
            className="nav-item discover-menu"
            onMouseEnter={handleMouseEnterDiscover}
            onMouseLeave={handleMouseLeaveDiscover}
          >
            <span>Discover</span>
          </li>

          {/* Static Link */}
          <li className="nav-item">
            WintonRail
            <span className="new-label">SOON</span>
          </li>
        </ul>

        {/* Vehicles Dropdown */}
        <div
          className={`vehicles-dropdown ${isVehiclesOpen ? "visible" : ""}`}
          onMouseEnter={handleMouseEnterVehicles}
          onMouseLeave={handleMouseLeaveVehicles}
        >
          <Link to="/silo-trailer-series" className="vehicle-item mt-3">
            <img src={vehicleLogo3} alt="Silo Trailer" />
            <p>Silo Trailer Series</p>
          </Link>
          <Link to="/tanker-series" className="vehicle-item mt-3">
            <img src={vehicleLogo1} alt="Tankers" />
            <p>Tanker Series</p>
          </Link>
          <Link to="/container-series" className="vehicle-item mt-3">
            <img src={vehicleLogo2} alt="Containers" />
            <p>Container Series</p>
          </Link>
          <Link to="/truck-mounted-series" className="vehicle-item mt-3">
            <img src={vehicleLogo4} alt="Truck Mounted" />
            <p>Truck Mounted Series</p>
          </Link>
        </div>

        {/* Discover Dropdown */}
        <div
          className={`discover-dropdown ${isDiscoverOpen ? "visible" : ""}`}
          onMouseEnter={handleMouseEnterDiscover}
          onMouseLeave={handleMouseLeaveDiscover}
        >
          <Link to="/about-us" className="discover-item mt-3">
            <p>About Us</p>
          </Link>
          <Link to="/network" className="discover-item mt-3">
            <p>Network</p>
          </Link>
          <Link to="/contact-us" className="discover-item mt-3">
            <p>Contact Us</p>
          </Link>
          <Link to="/download-center" className="discover-item mt-3">
            <p>Download Center</p>
          </Link>
        </div>

        {/* Language Selector */}
        <LanguageDropdown />
      </nav>
    </div>
  );
}

export default Navbar;
