import { useMediaQuery } from "react-responsive";
import SiloTrailerSeriesMobile from "./SiloTrailerSeriesMobile";
import SiloTrailerSeriesDesktop from "./SiloTrailerSeriesDesktop";

const SiloTrailerSeries = () => {
  const isMobile = useMediaQuery({ maxWidth: 768 });

  return isMobile ? <SiloTrailerSeriesMobile /> : <SiloTrailerSeriesDesktop />;
};

export default SiloTrailerSeries;
