import { useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import truckImg from "../../assets/images/home/truck2.jpg"; //truckmounted
import truck1 from "../../assets/images/truck/truck1.jpg";
import truck2 from "../../assets/images/truck/truck2.jpg";

import { ReactComponent as Technical } from "../../assets/images/truck/1truck.svg";
import { ReactComponent as Measurement } from "../../assets/images/truck/2truck.svg";

function TruckMountedSeriesDesktop() {
  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 2000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 6000,
  };

  const [activeTab, setActiveTab] = useState("technical");

  return (
    <div className="product-page">
      {/* Banner Section */}
      <div className="product-banner-container">
        <div className="relative w-full h-[90vh] bg-black overflow-hidden">
          <img
            src={truckImg}
            alt="truck"
            className="w-full h-[90vh] object-cover"
          />
          <div className="scroll-indicator-alt">
            <div className="mouse-icon-alt"></div>
            <p>Scroll Down</p>
          </div>
          <div className="absolute inset-0">
            <div className="absolute top-0 left-0 w-full h-1/4 bg-gradient-to-b from-black to-transparent"></div>
            <div className="absolute bottom-0 left-0 w-full h-1/4 bg-gradient-to-t from-black to-transparent"></div>
          </div>

          <div className="banner-header relative z-10 text-white text-center">
            <h1 className="md:text-5xl lg:text-6xl sm:text-3xl mt-12">
              Truck Mounted Series
            </h1>
          </div>
        </div>
      </div>

      <div className="impact-resistance-section">
        {/* Sabit Metin */}
        <div className="text-section pt-20">
          <h2>Agile by Design</h2>
          <p>
            Our truck-mounted units offer a low tare weight and great
            maneuverability. Their integrated design minimizes downtime and
            reduces maintenance, ensuring seamless operation in demanding
            environments.
          </p>
        </div>

        {/* Slider Bölümü */}
        <div className="image-section mt-6">
          <Slider {...sliderSettings}>
            <div>
              <img
                src={truck1}
                alt="Truck Mounted 1"
                className="w-full rounded-lg"
              />
            </div>
            <div>
              <img src={truck2} alt="Truck 2" className="w-full rounded-lg" />
            </div>
          </Slider>
        </div>
      </div>
      <div className="w-full max-w-4xl flex flex-col justify-start items-start mx-auto mt-12">
        <div className="flex justify-start gap-12 w-full mt-4 mb-4 ml-5">
          <h3
            className={`cursor-pointer text-xl transition-all duration-300 ${
              activeTab === "technical"
                ? "text-white"
                : "text-gray-500 hover:text-gray-300"
            }`}
            onClick={() => setActiveTab("technical")}
          >
            Technical Parameters
          </h3>
          <h3
            className={`cursor-pointer text-xl transition-all duration-300 ${
              activeTab === "measurements"
                ? "text-white"
                : "text-gray-500 hover:text-gray-300"
            }`}
            onClick={() => setActiveTab("measurements")}
          >
            Measurements
          </h3>
        </div>

        <div className="flex justify-start items-start w-full min-h-[500px] max-h-[500px] transition-all duration-500 ease-in-out mt-5">
          {activeTab === "technical" ? (
            <Technical
              alt="Technical Parameters"
              className="max-w-full max-h-[600px] object-contain my-svg"
            />
          ) : (
            <Measurement
              alt="Measurements"
              className="max-w-full max-h-[600px] object-contain my-svg"
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default TruckMountedSeriesDesktop;
