import { useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import containerImg from "../../assets/images/home/container.jpg";
import container1 from "../../assets/images/container/1container.jpg";
import container2 from "../../assets/images/container/2container.jpg";
import container3 from "../../assets/images/container/3container.jpg";

import { ReactComponent as Technical } from "../../assets/images/container/ContainerSeries-01.svg";
import { ReactComponent as Measurements } from "../../assets/images/container/ContainerSeries-02.svg";

function ContainerSeriesMobile() {
  const [activeIndex, setActiveIndex] = useState(0);

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    arrows: false,
    beforeChange: (oldIndex, newIndex) => setActiveIndex(newIndex),
    customPaging: (i) => (
      <div
        style={{
          margin: "12px",
          width: "12px",
          height: "12px",
          backgroundColor: i === activeIndex ? "gray" : "white",
          borderRadius: "50%",
        }}
      />
    ),
  };

  const [activeTab, setActiveTab] = useState("technical");

  return (
    <div className="container-series-mobile">
      <div className="product-banner-container">
        <div className="relative w-full h-[90vh] bg-black overflow-hidden">
          <img
            src={containerImg}
            alt="tanker"
            className="w-full h-[90vh] object-cover"
          />
          <div className="scroll-indicator-alt">
            <div className="mouse-icon-alt"></div>
            <p>Scroll Down</p>
          </div>
          <div className="absolute inset-0">
            <div className="absolute top-0 left-0 w-full h-1/4 bg-gradient-to-b from-black to-transparent"></div>
            <div className="absolute bottom-0 left-0 w-full h-1/4 bg-gradient-to-t from-black to-transparent"></div>
          </div>

          <div className="banner-header relative z-10 text-white text-center">
            <h1 className="md:text-5xl lg:text-6xl sm:text-3xl mt-12">
              Container Series
            </h1>
          </div>
        </div>
      </div>

      <div className="slider-container-mobile">
        <Slider {...sliderSettings}>
          {[container1, container2, container3].map((img, idx) => (
            <div key={idx} className="slider-item-mobile">
              <img src={img} alt={`Container ${idx + 1}`} />
            </div>
          ))}
        </Slider>
      </div>

      <div className="text-section-mobile">
        <h2>Built for the Long Haul</h2>
        <p>
          Versatile and efficient, our container silos come in tank, V-type,
          W-type (multi-compartment), and cylindrical designs. Available in 20
          to 45 feet, they feature aluminum or steel connection fittings,
          ensuring reliable bulk material transport for various industries.
        </p>
      </div>

      <div className="product-details-mobile">
        <div className="tabs-mobile">
          <h3
            className={activeTab === "technical" ? "active" : ""}
            onClick={() => setActiveTab("technical")}
          >
            Technical Parameters
          </h3>
          <h3
            className={activeTab === "measurements" ? "active" : ""}
            onClick={() => setActiveTab("measurements")}
          >
            Measurements
          </h3>
        </div>
        <div className="product-images-mobile">
          {activeTab === "technical" ? (
            <Technical alt="Technical Parameters" className="my-svg" />
          ) : (
            <Measurements alt="Measurements" className="my-svg" />
          )}
        </div>
      </div>
    </div>
  );
}

export default ContainerSeriesMobile;
