// FooterMobile.js
import React from "react";
import { FaTwitter, FaLinkedin, FaInstagram, FaYoutube } from "react-icons/fa";
import { Link } from "react-router-dom";

const FooterMobile = () => {
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <footer className="mobile-footer">
      <div className="mobile-footer-top">
        <div className="mobile-footer-card">
          <h3>Contact Us</h3>
          <p>+90 (312) 815 15 40</p>
          <p>
            Başkent OSB Başkent Bulvarı No:40 Malıköy, Temelli Ankara, Türkiye
          </p>
          <p>
            <a href="mailto:info@wintonindustries.com">
              info@wintonindustries.com
            </a>
          </p>
        </div>
      </div>

      <div className="mobile-footer-links-section">
        <div className="mobile-footer-column">
          <h4>PRODUCTS</h4>
          <ul>
            <li>
              <Link to="/silo-trailer-series" onClick={scrollToTop}>
                Silo Trailer Series
              </Link>
            </li>
            <li>
              <Link to="/tanker-series" onClick={scrollToTop}>
                Tanker Series
              </Link>
            </li>
            <li>
              <Link to="/container-series" onClick={scrollToTop}>
                Container Series
              </Link>
            </li>
            <li>
              <Link to="/truck-mounted-series" onClick={scrollToTop}>
                Truck Mounted Series
              </Link>
            </li>
          </ul>
        </div>
        <div className="mobile-footer-column">
          <h4>DISCOVER</h4>
          <ul>
            <li>
              <Link to="/about-us" onClick={scrollToTop}>
                About Us
              </Link>
            </li>
            <li>
              <Link to="/network" onClick={scrollToTop}>
                Network
              </Link>
            </li>
            <li>
              <Link to="/contact-us" onClick={scrollToTop}>
                Contact Us
              </Link>
            </li>
            <li>
              <Link to="/download-center" onClick={scrollToTop}>
                Download Center
              </Link>
            </li>
          </ul>
        </div>
      </div>

      <div className="mobile-footer-bottom">
        <div className="mobile-footer-social-icons">
          <a href="https://www.linkedin.com/company/winton-industries/?viewAsMember=true">
            <FaLinkedin />
          </a>
          <a href="https://www.youtube.com/@wintonindustries4684">
            <FaYoutube />
          </a>
          <a href="https://www.instagram.com/wintonindustries/">
            <FaInstagram />
          </a>
        </div>
      </div>

      <p className="mobile-footer-copy">© 2025 Winton Industries</p>
    </footer>
  );
};

export default FooterMobile;
