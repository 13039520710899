import React from "react";
import { FaTwitter, FaLinkedin, FaInstagram, FaYoutube } from "react-icons/fa";
import { Link } from "react-router-dom";

const FooterDesktop = () => {
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  return (
    <footer className="footer">
      <div className="footer-top">
        <div className="footer-card light">
          <h3>Call Us Now</h3>
          <p>Office Telephone: +90 (312) 815 15 40 </p>
        </div>
        <div className="footer-card dark">
          <h3>Come Visit Us</h3>
          <p>Başkent OSB Başkent Bulvarı No:40 Malıköy, Temelli </p>
          <p> Ankara, Türkiye</p>
        </div>
        <div className="footer-card light">
          <h3>Send Us A Message</h3>
          <p>
            <p> info@wintonindustries.com</p>
          </p>
        </div>
      </div>

      <div className="footer-bottom">
        <div className="footer-column ">
          <div className="footer-lists">
            <div className="footer-list">
              <h4>PRODUCTS</h4>
              <ul>
                <li>
                  <Link to="/silo-trailer-series" onClick={scrollToTop}>
                    Silo Trailer Series
                  </Link>
                </li>
                <li>
                  <Link to="/tanker-series" onClick={scrollToTop}>
                    Tanker Series
                  </Link>
                </li>
                <li>
                  <Link to="/container-series" onClick={scrollToTop}>
                    Container Series
                  </Link>
                </li>
                <li>
                  <Link to="/truck-mounted-series" onClick={scrollToTop}>
                    Truck Mounted Series
                  </Link>
                </li>
              </ul>
            </div>
            <div className="footer-list ml-10">
              <h4>DISCOVER</h4>
              <ul>
                <li>
                  <Link to="/about-us" onClick={scrollToTop}>
                    About Us
                  </Link>
                </li>
                <li>
                  <Link to="/network" onClick={scrollToTop}>
                    Network
                  </Link>
                </li>
                <li>
                  <Link to="/contact-us" onClick={scrollToTop}>
                    Contact Us
                  </Link>
                </li>
                <li>
                  <Link to="/download-center" onClick={scrollToTop}>
                    Download Center
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className="footer-column social-icons bg-transparent">
          <div className="icons-wrapper">
            <a href="https://www.linkedin.com/company/winton-industries/?viewAsMember=true">
              <FaLinkedin />
            </a>
            <a href="https://www.youtube.com/@wintonindustries4684">
              <FaYoutube />
            </a>
            <a href="https://www.instagram.com/wintonindustries/">
              <FaInstagram />
            </a>
          </div>
        </div>
      </div>

      <hr className="footer-divider" />

      <div className="footer-bottom-bar">
        <p>© Copyright 2025</p>
        <nav className="footer-links">
          <a href="#">Privacy Policy</a>
          <span>|</span>
          <a href="#">Terms</a>
        </nav>
      </div>
    </footer>
  );
};

export default FooterDesktop;
