import { useMediaQuery } from "react-responsive";
import AboutUsMobile from "./AboutUsMobile";
import AboutUsDesktop from "./AboutUsDesktop";

const AboutUs = () => {
  const isMobile = useMediaQuery({ maxWidth: 768 });

  return isMobile ? <AboutUsMobile /> : <AboutUsDesktop />;
};

export default AboutUs;
