import ContactForm from "../../components/Contact/ContactForm";
import { FaTwitter, FaLinkedin, FaInstagram, FaYoutube } from "react-icons/fa";

function ContactUs() {
  return (
    <div className="contact-page mt-6 px-4 sm:px-8 md:px-12 lg:px-20 py-8">
      <div className="flex flex-col md:flex-row gap-10">
        {/* Left Side */}
        <div className="md:w-1/2">
          <h1 className="text-3xl font-bold mb-4 text-center md:text-left">
            GET IN TOUCH
          </h1>
          <p className="text-center md:text-left mb-6">
            We are here to assist you with your inquiries.
            <br />
            Please fill out the following contact form or reach out to us
            directly.
          </p>

          <div className="space-y-6">
            <div>
              <h4 className="text-lg font-semibold">Customer Support</h4>
              <p className="mt-3 leading-relaxed">
                <a
                  href="mailto:info@wintonindustries.com"
                  className="text-red-600 underline"
                >
                  info@wintonindustries.com
                </a>
                <br />
                +90 312 815 15 40
                <br />
                Monday through Friday
                <br />
                08:15 - 18:15 (GMT+3)
              </p>
            </div>

            <div>
              <h4 className="text-lg font-semibold">Follow Us for Updates</h4>
              <p className="mt-3">
                Stay updated with our latest news, innovations, and events by
                following us on social media.
              </p>
              <div className="flex gap-4 mt-4 justify-center md:justify-start text-3xl">
                <a
                  href="https://www.linkedin.com/company/winton-industries/?viewAsMember=true"
                  className="text-red-600"
                >
                  <FaLinkedin />
                </a>
                <a
                  href="https://www.youtube.com/@wintonindustries4684"
                  className="text-red-600"
                >
                  <FaYoutube />
                </a>
                <a
                  href="https://www.instagram.com/wintonindustries/"
                  className="text-red-600"
                >
                  <FaInstagram />
                </a>
              </div>
            </div>
          </div>
        </div>

        {/* Right Side */}
        <div className="md:w-1/2">
          <ContactForm />
        </div>
      </div>
    </div>
  );
}

export default ContactUs;
