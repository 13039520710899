import React from "react";
import FreeMap from "./FreeMap";
import FreeMapMobile from "./FreeMapMobile";

const Network = () => {
  const isMobile = window.innerWidth <= 768;

  return isMobile ? <FreeMapMobile /> : <FreeMap />;
};

export default Network;
