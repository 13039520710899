import React, { useState, useEffect, useRef } from "react";
import video1 from "../../assets/images/aboutUs/about-us-video-1.webm";
import video2 from "../../assets/images/aboutUs/manufacturing.webm";
import qualityControl from "../../assets/images/aboutUs/quality-control.webm";
import perfectFinishing from "../../assets/images/aboutUs/perfect-finishing.webm";
import assemblyline from "../../assets/images/aboutUs/aseembly-line.webm";
import image1 from "../../assets/images/aboutUs/team-photo2.jpg";
import image2 from "../../assets/images/aboutUs/winton-mr-atilla.jpg";
import image3 from "../../assets/images/aboutUs/team-photo-inside.jpg";
import image4 from "../../assets/images/aboutUs/farabi-bey.jpg";
import bodyBuilder from "../../assets/images/aboutUs/bodyBuilder.webm";

import "swiper/css";
import { Swiper, SwiperSlide } from "swiper/react";

const AboutUsMobile = () => {
  const videosRef = useRef([]);
  const [loadedVideos, setLoadedVideos] = useState({});
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setLoadedVideos((prev) => ({
              ...prev,
              [entry.target.dataset.index]: true,
            }));
          }
        });
      },
      { threshold: 0.5 }
    );

    videosRef.current.forEach((video, index) => {
      if (video) observer.observe(video);
    });

    return () => {
      videosRef.current.forEach((video, index) => {
        if (video) observer.unobserve(video);
      });
    };
  }, []);

  const images = [
    {
      src: image1,
      title: "The Winton Team",
      desc: "Our dedicated team in action.",
    },
    { src: image2, title: "Mr. Atilla", desc: "Leadership in motion." },
    {
      src: image3,
      title: "Inside Team",
      desc: "Innovation at its best.",
    },
    { src: image4, title: "Farabi Bey", desc: "Quality and precision." },
  ];

  const slide1 = [
    {
      video: bodyBuilder,
      title: "Body Builder",
      description:
        "From raw metal to the final structure, our body builder machines handle every stage of production in-house, ensuring full control over quality and efficiency. Utilizing advanced technology, they optimize forming, welding, and assembly, delivering precision, durability, and seamless manufacturing.",
    },
    {
      video: video2,
      title: "Automation Welding",
      description:
        "Utilizing the latest technology, our automated welding solutions provide superior performance, increased reliability, and long-term efficiency, making them an essential part of high-precision manufacturing..",
    },
  ];

  return (
    <div className="about-us-mobile">
      {/* Banner */}
      <div className="relative w-full h-[80vh] bg-black overflow-hidden mb-5">
        <video
          src={video1}
          playsInline
          webkit-playsinline="true"
          muted
          loop
          autoPlay
          className="w-full h-full object-cover"
        />

        {/* İçerik */}
        <div className="absolute inset-0 flex flex-col items-center justify-between text-white text-center p-6">
          {/* En üstte başlık */}
          <h1 className="text-4xl font-semibold mt-6">About Us</h1>

          {/* Sayılar ve açıklamalar (yan yana hizalı) */}
          <div className="flex justify-center gap-6 w-full mt-auto mb-10">
            <div>
              <h1 className="text-2xl font-bold">7 ha</h1>
              <h2 className="text-sm font-light">Factory Space</h2>
            </div>
            <div>
              <h1 className="text-2xl font-bold">4</h1>
              <h2 className="text-sm font-light">Integrated Facilities</h2>
            </div>
            <div>
              <h1 className="text-2xl font-bold">720 +</h1>
              <h2 className="text-sm font-light">Vehicles per Year</h2>
            </div>
          </div>
        </div>
      </div>

      {/* Manufacturing Section */}
      <div className="video-section flex flex-col text-white pb-6 pt-6 mt-3 mb-3">
        <video
          ref={(el) => (videosRef.current[0] = el)}
          data-index="0"
          src={loadedVideos[0] ? video2 : ""}
          autoPlay={loadedVideos[0]}
          playsInline
          webkit-playsinline="true"
          muted
          loop
          preload="none"
          className="w-full object-cover"
        />
        <div className="p-4 text-left">
          <h2 className="font-bold text-xl">Manufacturing</h2>
          <p className="text-sm">
            Our vehicles undergo a sophisticated journey of designing,
            engineering, and assembling, meticulously crafted to meet the
            highest standards of safety, durability, and efficiency.
          </p>
        </div>
      </div>

      <div className="p-6">
        <Swiper spaceBetween={20} slidesPerView={1.2} className="pb-6">
          {slide1.map((slide, index) => (
            <SwiperSlide key={index} className="flex flex-col items-center">
              {/* Üstte Video */}
              <video
                ref={(el) => (videosRef.current[index] = el)}
                data-index={index}
                src={loadedVideos[index] ? slide.video : ""}
                autoPlay={loadedVideos[index]}
                playsInline
                webkit-playsinline="true"
                muted
                loop
                className="w-full h-60 object-cover rounded-lg"
              />

              {/* Altta Metin */}
              <div className="text-white text-left w-full mt-3 p-1 bg-black rounded-lg">
                <h3 className="font-bold text-lg">{slide.title}</h3>
                <p className="text-sm">{slide.description}</p>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>

      {/* Quality Control Section */}
      <div className="video-section flex flex-col text-white pb-6 pt-6 mt-3 mb-3">
        <video
          ref={(el) => (videosRef.current[1] = el)}
          data-index="1"
          src={loadedVideos[1] ? qualityControl : ""}
          autoPlay={loadedVideos[1]}
          playsInline
          webkit-playsinline="true"
          muted
          loop
          className="w-full object-cover"
        />
        <div className="p-4 text-left">
          <h2 className="font-bold text-xl">Quality Control</h2>
          <p className="text-sm">
            We manufacture our silo trailers in accordance with EN 13445 and PED
            2014/68/EU standards.
          </p>
        </div>
      </div>

      {/* Perfect Finishing Section */}
      <div className="video-section flex flex-col text-white pb-6 pt-6 mb-3">
        <video
          ref={(el) => (videosRef.current[2] = el)}
          data-index="2"
          src={loadedVideos[2] ? perfectFinishing : ""}
          autoPlay={loadedVideos[2]}
          playsInline
          webkit-playsinline="true"
          muted
          loop
          className="w-full object-cover"
        />
        <div className="p-4 text-left">
          <h2 className="font-bold text-xl">Perfect Finishing</h2>
          <p className="text-sm">
            Our facility boasts 10 painting booths distributed across 2 lines,
            each running 60 meters long, equipped with digital environmental
            monitoring systems and ex-proof man lifts for a perfect finishing.
          </p>
        </div>
      </div>

      {/* Assembly Line Section */}
      <div className="video-section flex flex-col text-white pb-6 pt-6 mt-3 mb-3">
        <video
          ref={(el) => (videosRef.current[3] = el)}
          data-index="3"
          src={loadedVideos[3] ? assemblyline : ""}
          autoPlay={loadedVideos[3]}
          playsInline
          webkit-playsinline="true"
          muted
          loop
          className="w-full object-cover"
        />
        <div className="p-4 text-left">
          <h2 className="font-bold text-xl">Assembly Line</h2>
          <p className="text-sm">
            Our tailor-made vehicles are delivered to clients after being
            assembled with impeccable craftsmanship. Each vehicle undergoes
            rigorous testing to ensure it meets the highest standards of quality
            and performance.
          </p>
        </div>
      </div>
      <div className="p-6">
        <h2 className="text-2xl font-bold text-white mb-4">The Winton Team</h2>
        <Swiper spaceBetween={20} slidesPerView={1.2} className="pb-6">
          {images.map((image, index) => (
            <SwiperSlide key={index} className="flex flex-col items-center">
              <img
                src={image.src}
                alt={image.title}
                className="w-full h-60 object-cover rounded-lg"
                loading="lazy"
              />
              <div className="text-white text-left w-full mt-3">
                <h3 className="font-bold text-lg">{image.title}</h3>
                <p className="text-sm">{image.desc}</p>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
};

export default AboutUsMobile;
