import { useMediaQuery } from "react-responsive";
import HomeMobile from "./HomeMobile";
import HomeDesktop from "./HomeDesktop";

const AboutUs = () => {
  const isMobile = useMediaQuery({ maxWidth: 768 });

  return isMobile ? <HomeMobile /> : <HomeDesktop />;
};

export default AboutUs;
