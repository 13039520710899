import React, { useState } from "react";
import { MapContainer, TileLayer, Marker, useMap } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import icon1 from "../../assets/images/network/network1.svg";
import icon2 from "../../assets/images/network/network2.svg";
import clicked2 from "../../assets/images/network/clicked2.svg";

const customIcon = new L.Icon({
  iconUrl: icon1,
  iconSize: [30, 30],
  iconAnchor: [15, 30],
  popupAnchor: [0, -30],
});

const headquartersIcon = new L.Icon({
  iconUrl: icon2,
  iconSize: [30, 30],
  iconAnchor: [15, 30],
  popupAnchor: [0, -30],
});

const headquartersEnlargedIcon = new L.Icon({
  iconUrl: clicked2,
  iconSize: [60, 60],
  iconAnchor: [30, 60],
  popupAnchor: [0, -60],
});

const stores = [
  { id: 1, lat: 39.804, lng: 32.387 },
  { id: 2, lat: 51.1996, lng: 4.7442 },
  { id: 3, lat: 44.3783, lng: 25.9305 },
  { id: 4, lat: 44.1776, lng: 28.6366 },
  { id: 5, lat: 52.8476, lng: 8.0356 },
  { id: 6, lat: 40.4353, lng: 15.0006 },
  { id: 7, lat: 39.1513, lng: -5.9145 },
  { id: 8, lat: 50.5229, lng: 30.4997 },
  { id: 9, lat: 43.4134, lng: 23.2262 },
  { id: 10, lat: 40.9819, lng: 27.9251 },
  { id: 11, lat: 50.0164, lng: 22.5254 },
];

const ZoomControlComponent = () => {
  const map = useMap();
  React.useEffect(() => {
    const zoomControl = L.control.zoom({
      position: "bottomright",
    });
    zoomControl.addTo(map);
    return () => {
      zoomControl.remove();
    };
  }, [map]);
  return null;
};

const FreeMapMobile = () => {
  const [isHeadquarterActive, setIsHeadquarterActive] = useState(false);

  return (
    <div className="map-wrapper-mobile">
      <MapContainer
        center={[44.9, 23.85]}
        zoom={2}
        minZoom={4}
        maxZoom={18}
        className="map-mobile"
      >
        <TileLayer url="https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png" />
        {stores.map((store) => (
          <Marker
            key={store.id}
            position={[store.lat, store.lng]}
            icon={
              store.id === 1
                ? isHeadquarterActive
                  ? headquartersEnlargedIcon
                  : headquartersIcon
                : customIcon
            }
          />
        ))}
        <ZoomControlComponent />
      </MapContainer>
      <div className="info-box-mobile">
        <h2 style={{ fontSize: "14px" }}>Our Headquarter</h2>
        <p className="sales-type-mobile" style={{ fontSize: "10px" }}>
          Plant & Headquarters
        </p>
        <p className="full-address-mobile" style={{ fontSize: "10px" }}>
          Başkent OSB Başkent Bulvarı No: 40 Malıköy Sincan/Ankara
        </p>
        <hr />
        <h3 style={{ fontSize: "12px" }}>Access & Hours</h3>
        <p style={{ fontSize: "10px" }}>Monday-Friday: 08:30 - 18:00</p>
        <hr />
        <h3 style={{ fontSize: "12px" }}>Contact</h3>
        <p style={{ fontSize: "10px" }}>
          <strong>Phone:</strong> +90 (312) 815 15 40
        </p>
        <p style={{ fontSize: "10px" }}>
          <strong>Email: </strong>
          <a
            href="mailto:info@wintonindustries.com"
            style={{ fontSize: "10px" }}
          >
            info@wintonindustries.com
          </a>
        </p>
        <div
          style={{ fontSize: "12px" }}
          className="view-on-map-mobile"
          onClick={() => setIsHeadquarterActive(!isHeadquarterActive)}
        >
          View on Map
        </div>
      </div>
    </div>
  );
};

export default FreeMapMobile;
