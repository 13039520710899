import React from "react";
import { useNavigate } from "react-router-dom";
import tankerImg from "../../assets/images/home/tanker.jpg";
import containerImg from "../../assets/images/home/container.jpg";
import siloImg from "../../assets/images/home/silo.jpg";
import truckImg2 from "../../assets/images/home/truck2.jpg";
import hVideo from "../../assets/images/home/home.mp4";

function HomeMobile() {
  const navigate = useNavigate();

  // Yönlendirme fonksiyonu + Scroll to top
  const handleNavigation = (path) => {
    navigate(path); // Sayfaya yönlendir
    window.scrollTo({ top: 0, behavior: "smooth" }); // Sayfanın en üstüne kaydır
  };

  return (
    <>
      {/* Video Bölümü */}
      <section className="mobile-section video-section">
        <video
          className="mobile-background-video"
          src={hVideo}
          autoPlay
          loop
          muted
          playsInline
        />
        <div className="mobile-scroll-indicator">
          <div className="mobile-mouse-icon"></div>
          <p>Scroll Down</p>
        </div>
      </section>

      {/* Ürün Bölümleri */}
      <section
        className="mobile-section"
        style={{ backgroundImage: `url(${tankerImg})` }}
      >
        <div className="mobile-overlay">
          <div className="mobile-section-text">
            <h1 className="mobile-section-title">Tanker Series</h1>
          </div>
          <div className="mobile-section-buttons">
            <button
              className="mobile-button-primary"
              onClick={() => handleNavigation("/tanker-series")}
            >
              Learn More
            </button>
          </div>
        </div>
      </section>

      <section
        className="mobile-section"
        style={{ backgroundImage: `url(${containerImg})` }}
      >
        <div className="mobile-overlay">
          <div className="mobile-section-text">
            <h1 className="mobile-section-title">Container Series</h1>
          </div>
          <div className="mobile-section-buttons">
            <button
              className="mobile-button-primary"
              onClick={() => handleNavigation("/container-series")}
            >
              Learn More
            </button>
          </div>
        </div>
      </section>

      <section
        className="mobile-section"
        style={{ backgroundImage: `url(${siloImg})` }}
      >
        <div className="mobile-overlay">
          <div className="mobile-section-text">
            <h1 className="mobile-section-title">Silo Trailer Series</h1>
          </div>
          <div className="mobile-section-buttons">
            <button
              className="mobile-button-primary"
              onClick={() => handleNavigation("/silo-trailer-series")}
            >
              Learn More
            </button>
          </div>
        </div>
      </section>

      <section
        className="mobile-section"
        style={{ backgroundImage: `url(${truckImg2})` }}
      >
        <div className="mobile-overlay">
          <div className="mobile-section-text">
            <h1 className="mobile-section-title">Truck Mounted Series</h1>
          </div>
          <div className="mobile-section-buttons">
            <button
              className="mobile-button-primary"
              onClick={() => handleNavigation("/truck-mounted-series")}
            >
              Learn More
            </button>
          </div>
        </div>
      </section>
    </>
  );
}

export default HomeMobile;
