import React, { useState } from "react";
import { MapContainer, TileLayer, Marker, useMap } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import icon1 from "../../assets/images/network/network1.svg";
import icon2 from "../../assets/images/network/network2.svg";
import clicked2 from "../../assets/images/network/clicked2.svg";

const customIcon = new L.Icon({
  iconUrl: icon1,
  iconSize: [40, 40],
  iconAnchor: [20, 40],
  popupAnchor: [0, -40],
});

const headquartersIcon = new L.Icon({
  iconUrl: icon2,
  iconSize: [40, 40],
  iconAnchor: [20, 40],
  popupAnchor: [0, -40],
});

const headquartersEnlargedIcon = new L.Icon({
  iconUrl: clicked2,
  iconSize: [80, 80],
  iconAnchor: [40, 80],
  popupAnchor: [0, -80],
});

const stores = [
  {
    id: 1,
    type: "Plant & Headquarters",
    name: "WINTON OTOMOTIV ENDUSTRI A.Ş.",
    lat: 39.804,
    lng: 32.387,
    address: "Başkent OSB Başkent Bulvarı No: 40 Malıköy Sincan/Ankara",
    phone: " +90 (312) 815 15 40",
    email: " info@wintonindustries.com ",
    workHours:
      "Monday-Friday: 08:30-18:00, Saturday: 08:30-13:00, Sunday: Closed",
  },
  {
    id: 2,
    lat: 51.1996,
    lng: 4.7442,
  },
  {
    id: 3,
    lat: 44.3783,
    lng: 25.9305,
  },
  {
    id: 4,
    lat: 44.1776,
    lng: 28.6366,
  },
  {
    id: 5,
    lat: 52.8476,
    lng: 8.0356,
  },
  {
    id: 6,
    lat: 40.4353,
    lng: 15.0006,
  },
  {
    id: 7,
    lat: 39.1513,
    lng: -5.9145,
  },
  {
    id: 8,
    lat: 50.5229,
    lng: 30.4997,
  },
  {
    id: 9,
    lat: 43.4134,
    lng: 23.2262,
  },
  {
    id: 10,
    lat: 40.9819,
    lng: 27.9251,
  },
  {
    id: 11,
    lat: 50.0164,
    lng: 22.5254,
  },
];

const ZoomControlComponent = () => {
  const map = useMap();
  React.useEffect(() => {
    const zoomControl = L.control.zoom({
      position: "bottomright",
    });
    zoomControl.addTo(map);
    return () => {
      zoomControl.remove();
    };
  }, [map]);
  return null;
};

const FreeMap = () => {
  const [isHeadquarterActive, setIsHeadquarterActive] = useState(false);

  return (
    <div className="map-container">
      <div className="info-box">
        <h2 className="">Our Headquarters</h2>
        <p className="sales-type" style={{ fontSize: "12px", color: "#666" }}>
          Plant & Headquarters
        </p>
        <p className="full-address">
          Başkent OSB Başkent Bulvarı No: 40 Malıköy Sincan/Ankara
        </p>
        <hr style={{ borderTop: "1px solid #ddd" }} />
        <h3 style={{ fontSize: "14px", fontWeight: "bold" }}>Access & Hours</h3>
        <p style={{ fontSize: "13px" }}>
          Monday Through Friday 08:15 - 18:15 (GMT+3)
        </p>
        <hr style={{ borderTop: "1px solid #ddd" }} />
        <h3 style={{ fontSize: "14px", fontWeight: "bold" }}>Contact</h3>
        <p style={{ fontSize: "13px" }}>
          <strong>Phone:</strong> +90 (312) 815 15 40
        </p>
        <p style={{ fontSize: "13px" }}>
          <strong>Email: </strong>
          <a
            href="mailto:info@wintonindustries.com"
            style={{ color: "red", textDecoration: "none" }}
          >
            info@wintonindustries.com
          </a>
        </p>
        <span
          onClick={() => setIsHeadquarterActive(!isHeadquarterActive)}
          style={{
            marginTop: "10px",
            display: "inline-block",
            color: "black",
            textDecoration: "underline",
            cursor: "pointer",
            fontSize: "14px",
          }}
        >
          View on Map
        </span>
      </div>
      <MapContainer
        center={[48, 10]}
        zoom={5}
        minZoom={5}
        maxZoom={18}
        className="map"
        zoomControl={false}
      >
        <TileLayer url="https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png" />
        {stores.map((store) => (
          <Marker
            key={store.id}
            position={[store.lat, store.lng]}
            icon={
              store.id === 1
                ? isHeadquarterActive
                  ? headquartersEnlargedIcon
                  : headquartersIcon
                : customIcon
            }
          />
        ))}
        <ZoomControlComponent />
      </MapContainer>
    </div>
  );
};

export default FreeMap;
