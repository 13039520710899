import React, { useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FaQuestionCircle, FaChevronDown } from "react-icons/fa";

import { Tooltip } from "react-tooltip";
import tankerMov from "../../assets/images/TankerSeries/petroleum-banner.webm";

import { ReactComponent as Bitumen_Technical } from "../../assets/images/bitumen/TankerSeries-15.svg";
import { ReactComponent as Bitumen_Measurements } from "../../assets/images/bitumen/TankerSeries-16.svg";

import { ReactComponent as Fuel34_4_Techinal } from "../../assets/images/fuel/34.4/TankerSeries-01.svg";
import { ReactComponent as Fuel34_4_Measurements } from "../../assets/images/fuel/34.4/TankerSeries-08.svg";

import { ReactComponent as Fuel34_5_Techinal } from "../../assets/images/fuel/34.5/TankerSeries-02.svg";
import { ReactComponent as Fuel34_5_Measurements } from "../../assets/images/fuel/34.5/TankerSeries-09.svg";

import { ReactComponent as Fuel34_6_Techinal } from "../../assets/images/fuel/34.6/TankerSeries-03.svg";
import { ReactComponent as Fuel34_6_Measurements } from "../../assets/images/fuel/34.6/TankerSeries-10.svg";

import { ReactComponent as Fuel38_5_Techinal } from "../../assets/images/fuel/38.5/TankerSeries-04.svg";
import { ReactComponent as Fuel38_5_Measurements } from "../../assets/images/fuel/38.5/TankerSeries-11.svg";

import { ReactComponent as Fuel38_6_Techinal } from "../../assets/images/fuel/38.6/TankerSeries-05.svg";
import { ReactComponent as Fuel38_6_Measurements } from "../../assets/images/fuel/38.6/TankerSeries-12.svg";

import { ReactComponent as Fuel38_7_Techinal } from "../../assets/images/fuel/38.7/TankerSeries-06.svg";
import { ReactComponent as Fuel38_7_Measurements } from "../../assets/images/fuel/38.7/TankerSeries-13.svg";

import { ReactComponent as Fuel41_1_Techinal } from "../../assets/images/fuel/41.1/TankerSeries-07.svg";
import { ReactComponent as Fuel41_1_Measurements } from "../../assets/images/fuel/41.1/TankerSeries-14.svg";

import bitumen1 from "../../assets/images/bitumen/1bitumen.jpg";
import bitumen2 from "../../assets/images/bitumen/2bitumen.jpg";
import fuel1 from "../../assets/images/fuel/1fuel.jpg";
import fuel2 from "../../assets/images/fuel/2fuel.jpg";
import fuel3 from "../../assets/images/fuel/3fuel.jpg";

function TankerSeriesMobile() {
  const [activeSlider, setActiveSlider] = useState("bitumen");
  const [activeIndex, setActiveIndex] = useState(0);

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    arrows: false,
    beforeChange: (oldIndex, newIndex) => setActiveIndex(newIndex),
    customPaging: (i) => (
      <div
        style={{
          margin: "12px",
          width: "12px",
          height: "12px",
          backgroundColor: i === activeIndex ? "gray" : "white",
          borderRadius: "50%",
        }}
      />
    ),
  };
  const products = [
    {
      category: "BITUMEN",
      codes: [
        {
          id: "Bitumen",
          technical: Bitumen_Technical,
          measurements: Bitumen_Measurements,
        },
      ],
    },
    {
      category: "FUEL",
      codes: [
        {
          id: "34.4",
          technical: Fuel34_4_Techinal,
          measurements: Fuel34_4_Measurements,
        },
        {
          id: "34.5",
          technical: Fuel34_5_Techinal,
          measurements: Fuel34_5_Measurements,
        },
        {
          id: "34.6",
          technical: Fuel34_6_Techinal,
          measurements: Fuel34_6_Measurements,
        },
        {
          id: "38.5",
          technical: Fuel38_5_Techinal,
          measurements: Fuel38_5_Measurements,
        },
        {
          id: "38.6",
          technical: Fuel38_6_Techinal,
          measurements: Fuel38_6_Measurements,
        },
        {
          id: "38.7",
          technical: Fuel38_7_Techinal,
          measurements: Fuel38_7_Measurements,
        },
        {
          id: "41.1",
          technical: Fuel41_1_Techinal,
          measurements: Fuel41_1_Measurements,
        },
      ],
    },
  ];

  const [activeCategory, setActiveCategory] = useState("FUEL");
  const [selectedProduct, setSelectedProduct] = useState("");
  const [activeTab, setActiveTab] = useState("technical");
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const selectedProductData =
    products
      .flatMap((group) => group.codes)
      .find((p) => p.id === selectedProduct) || {};

  return (
    <div className="responsive-product-page">
      <div className="product-banner-container">
        <div className="relative w-full h-[90vh] bg-black overflow-hidden">
          <video
            className="background-video w-full h-full object-cover"
            src={tankerMov}
            autoPlay
            loop
            muted
            playsInline
          />
          <div className="scroll-indicator-alt">
            <div className="mouse-icon-alt"></div>
            <p>Scroll Down</p>
          </div>
          <div className="absolute inset-0">
            <div className="absolute top-0 left-0 w-full h-1/4 bg-gradient-to-b from-black to-transparent"></div>
            <div className="absolute bottom-0 left-0 w-full h-1/4 bg-gradient-to-t from-black to-transparent"></div>
          </div>

          <div className="banner-header relative z-10 text-white">
            <h1 className="md:text-5xl lg:text-6xl sm:text-3xl mt-12">
              Tanker Series
            </h1>
          </div>
        </div>
      </div>
      <div className="product-section-mobile">
        <div className="text-container-mobile">
          <h4
            className={activeSlider === "bitumen" ? "active" : ""}
            onClick={() => setActiveSlider("bitumen")}
          >
            Bitumen
          </h4>
          <h4
            className={activeSlider === "fuel" ? "active" : ""}
            onClick={() => setActiveSlider("fuel")}
          >
            Fuel
          </h4>
        </div>
        <div className="slider-container-mobile">
          <Slider {...sliderSettings}>
            {activeSlider === "bitumen"
              ? [bitumen1, bitumen2].map((img, idx) => (
                  <div key={idx} className="slider-item-mobile">
                    <img src={img} alt="Bitumen" />
                  </div>
                ))
              : [fuel1, fuel2, fuel3].map((img, idx) => (
                  <div key={idx} className="slider-item-mobile">
                    <img src={img} alt="Fuel" />
                  </div>
                ))}
          </Slider>
          <div className="text-content-mobile">
            {activeSlider === "bitumen" ? (
              <>
                <h2>Built for Endurance </h2>
                <p>
                  Our bitumen tankers feature burner and coil heater systems
                  that maintain stable temperatures with automatic sensor
                  control. Available in stainless and carbon steel, they offer
                  insulation optimized for operational weather conditions. With
                  a stainless steel mirror finish, chassis options in stainless
                  steel or S700MC, and flush or quick gate valve unloading
                  systems, they ensure durability and easy maintenance.
                </p>
              </>
            ) : (
              <>
                <h2>Fueled by Precision </h2>
                <p>
                  Designed for stability and efficiency, our fuel tank trailers
                  feature a low center of gravity when fully loaded and an
                  optimized tare weight. Equipped with vapor valves, recovery
                  adaptors, overfill sensors, and pneumatic control panels, they
                  also offer pump, flow meter, and hose reel options, ensuring
                  seamless operation.
                </p>
              </>
            )}
          </div>
        </div>
      </div>
      <div className="product-codes-mobile">
        <div className="category-selector-mobile">
          <button
            className={activeCategory === "BITUMEN" ? "active" : ""}
            onClick={() => setActiveCategory("BITUMEN")}
          >
            Bitumen
          </button>
          <button
            className={activeCategory === "FUEL" ? "active" : ""}
            onClick={() => setActiveCategory("FUEL")}
          >
            Fuel
          </button>
        </div>

        <div className="dropdown-container-mobile">
          <button
            className="dropdown-btn-mobile"
            onClick={() => setDropdownOpen(!dropdownOpen)}
          >
            Select a Product <FaChevronDown />
          </button>
          {dropdownOpen && (
            <ul className="dropdown-menu-mobile">
              {products
                .find((group) => group.category === activeCategory)
                ?.codes.map((code) => (
                  <li
                    key={code.id}
                    onClick={() => {
                      setSelectedProduct(code.id);
                      setDropdownOpen(false);
                    }}
                  >
                    {code.id}
                  </li>
                ))}
            </ul>
          )}
        </div>

        {selectedProduct && (
          <div className="product-details-mobile">
            <div className="tabs-mobile">
              <h3
                className={activeTab === "technical" ? "active" : ""}
                onClick={() => setActiveTab("technical")}
              >
                Technical Parameters
              </h3>
              <h3
                className={activeTab === "measurements" ? "active" : ""}
                onClick={() => setActiveTab("measurements")}
              >
                Measurements
              </h3>
            </div>
            <div className="product-images-mobile">
              {activeTab === "technical" ? (
                <selectedProductData.technical
                  alt="Technical Parameters"
                  className="my-svg"
                />
              ) : (
                <selectedProductData.measurements
                  alt="Measurements"
                  className="my-svg"
                />
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default TankerSeriesMobile;
