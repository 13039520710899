import React, { useEffect, useRef } from "react";
import video1 from "../../assets/images/aboutUs/about-us-video-1.webm";
import video2 from "../../assets/images/aboutUs/manufacturing.webm";
import qualityControl from "../../assets/images/aboutUs/quality-control.webm";
import perfectFinishing from "../../assets/images/aboutUs/perfect-finishing.webm";
import assemblyline from "../../assets/images/aboutUs/aseembly-line.webm";
import image1 from "../../assets/images/aboutUs/team-photo2.jpg";
import image2 from "../../assets/images/aboutUs/winton-mr-atilla.jpg";
import image3 from "../../assets/images/aboutUs/team-photo-inside.jpg";
import image4 from "../../assets/images/aboutUs/farabi-bey.jpg";
import CustomSlider from "../../components/CustomSlider.tsx";
import BackToTopButton from "../../components/BackToTopButton";
import bodyBuilder from "../../assets/images/aboutUs/bodyBuilder.webm";

function AboutUsDesktop() {
  const textRefs = useRef([]); // Sadece animasyon uygulanacak yazılar için referanslar
  const images = [image1, image2, image3, image4];
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("visible"); // Görünür olduğunda animasyon başlatılır
          } else {
            entry.target.classList.remove("visible"); // Görünürlüğü kaybettiğinde animasyon sıfırlanır
          }
        });
      },
      { threshold: 0.8 } // Elemanın %50'si göründüğünde tetiklenecek
    );

    textRefs.current.forEach((ref) => {
      if (ref) observer.observe(ref);
    });

    return () => {
      textRefs.current.forEach((ref) => {
        if (ref) observer.unobserve(ref);
      });
    };
  }, []);

  return (
    <div className="about-us-page">
      <div className="banner-container">
        <div className="relative w-full h-[93vh] bg-black overflow-hidden">
          {/* Video Element */}
          <video
            src={video1}
            playsInline
            webkit-playsinline="true"
            muted
            loop
            autoPlay
            className="w-full h-full object-cover"
          />
          {/* Gradient Overlay */}
          <div className="absolute inset-0">
            {/* Top Gradient */}
            <div className="absolute top-0 left-0 w-full h-1/4 bg-gradient-to-b from-black to-transparent"></div>
            {/* Bottom Gradient */}
            <div className="absolute bottom-0 left-0 w-full h-1/4 bg-gradient-to-t from-black to-transparent"></div>
          </div>

          {/* Text Content */}
          <div className="banner-header relative z-10 text-white text-center">
            <h1 className="md:text-5xl lg:text-6xl sm:text-3xl mt-12 font-medium">
              About Us
            </h1>

            <div className="banner-footer flex justify-around mt-6">
              <div>
                <h1 className="text-4xl font-normal">7 ha</h1>
                <h2 className="text-xl font-light">Factory Space</h2>
              </div>
              <div>
                <h1 className="text-4xl font-normal">4</h1>
                <h2 className="text-xl font-light">Integrated Facilities</h2>
              </div>
              <div>
                <h1 className="text-4xl font-normal">720 +</h1>
                <h2 className="text-xl font-light">Vehicles per Year</h2>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="video-content-container">
        <div className="video-wrapper relative w-full h-auto overflow-hidden">
          {/* Video Element */}
          <video
            src={video2}
            playsInline
            webkit-playsinline="true"
            muted
            loop
            autoPlay
            className="video-bg w-full object-cover"
          />
          <div className="absolute inset-0 pointer-events-none">
            <div className="absolute bottom-0 left-0 w-full h-1/4 bg-gradient-to-t from-black to-transparent"></div>
          </div>
        </div>

        <div className="text-wrapper">
          <h2 className="title">Manufacturing</h2>
          <p className="description">
            Our vehicles undergo a sophisticated journey of designing,
            engineering, and assembling, meticulously crafted to meet the
            highest standards of safety, durability, and efficiency.
          </p>
        </div>
      </div>
      <div className="card-container">
        <div className="card-row">
          <div className="card video-card-1">
            <video
              playsInline
              webkit-playsinline="true"
              muted
              loop
              autoPlay
              src={bodyBuilder}
            />
          </div>

          <div className="card text-card-1">
            <h2
              className="card-title animate"
              ref={(el) => textRefs.current.push(el)}
            >
              Body Builder
            </h2>
            <p
              className="card-description animate"
              ref={(el) => textRefs.current.push(el)}
            >
              From raw metal to the final structure, our body builder machines
              handle
              <br />
              every stage of production in-house, ensuring full control over
              quality and efficiency.
              <br />
              Utilizing advanced technology, they optimize forming, welding, and
              assembly, delivering precision, durability, and seamless
              manufacturing.
            </p>
          </div>
        </div>
        <div className="card-row">
          <div className="card text-card-2">
            <h2
              className="card-title animate"
              ref={(el) => textRefs.current.push(el)}
            >
              Automation Welding
            </h2>
            <p
              className="card-descriptio animate"
              ref={(el) => textRefs.current.push(el)}
            >
              Utilizing the latest technology, our automated welding solutions
              provide
              <br />
              superior performance, increased reliability, and long-term
              efficiency,
              <br />
              making them an essential part of high-precision manufacturing.
            </p>
          </div>
          <div className="card video-card-2">
            <video
              playsInline
              webkit-playsinline="true"
              muted
              loop
              autoPlay
              src={video2}
            />
          </div>
        </div>
      </div>

      <div className="video-content-container">
        <div className="video-wrapper relative w-full h-auto overflow-hidden">
          {/* Video Element */}
          <video
            src={qualityControl}
            playsInline
            webkit-playsinline="true"
            muted
            loop
            autoPlay
            className="video-bg w-full h-auto object-cover"
          />

          {/* Gradient Overlay */}
          <div className="absolute inset-0 pointer-events-none">
            {/* Bottom Gradient */}
            <div className="absolute bottom-0 left-0 w-full h-1/4 bg-gradient-to-t from-black to-transparent"></div>
          </div>
        </div>

        <div className="text-wrapper">
          <h2 className="title">Quality Control</h2>
          <p className="description">
            We manufacture our silo trailers in accordance with EN 13445 and PED
            2014/68/EU standards.
          </p>
        </div>
      </div>

      <div className="video-content-container">
        <div className="video-wrapper relative w-full h-auto overflow-hidden">
          <video
            src={perfectFinishing}
            playsInline
            webkit-playsinline="true"
            muted
            loop
            autoPlay
            className="video-bg w-full h-auto object-cover"
          />

          {/* Gradient Overlay */}
          <div className="absolute inset-0 pointer-events-none">
            {/* Bottom Gradient */}
            <div className="absolute bottom-0 left-0 w-full h-1/4 bg-gradient-to-t from-black to-transparent"></div>
          </div>
        </div>
        <div className="text-wrapper">
          <h3 className="title">The Perfect Finishing</h3>
          <p className="description">
            Our facility boasts 10 painting booths distributed across 2 lines,
            each running 60 meters long, equipped with digital environmental
            monitoring systems and ex-proof man lifts for a perfect finishing.
          </p>
        </div>
      </div>
      <div className="video-content-container">
        <div className="video-wrapper relative w-full h-auto overflow-hidden">
          <video
            src={assemblyline}
            playsInline
            webkit-playsinline="true"
            muted
            loop
            autoPlay
            className="video-bg w-full h-auto object-cover"
          />
          <div className="absolute inset-0 pointer-events-none">
            <div className="absolute bottom-0 left-0 w-full h-1/4 bg-gradient-to-t from-black to-transparent"></div>
          </div>
        </div>
        <div className="text-wrapper">
          <h3>Assembly Line</h3>
          <p className="description">
            Our tailor-made vehicles are delivered to clients after being
            assembled with impeccable craftsmanship. Each vehicle undergoes
            rigorous testing to ensure it meets the highest standards of quality
            and performance.
          </p>
        </div>
      </div>
      <CustomSlider
        images={images}
        title="The Winton Team"
        description="Join us and become part of our vibrant team, where collaboration and a
          shared commitment to teamwork define our success. You’ll find
          opportunities for growth and development in an environment that values
          your contributions."
      />
    </div>
  );
}

export default AboutUsDesktop;
