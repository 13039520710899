import { useMediaQuery } from "react-responsive";
import ContainerSeriesMobile from "./ContainerSeriesMobile";
import ContainerSeriesDesktop from "./ContainerSeriesDesktop";

const ContainerSeries = () => {
  const isMobile = useMediaQuery({ maxWidth: 768 });

  return isMobile ? <ContainerSeriesMobile /> : <ContainerSeriesDesktop />;
};

export default ContainerSeries;
