import { useMediaQuery } from "react-responsive";
import TruckMountedSeriesMobile from "./TruckMountedSeriesMobile";
import TruckMountedSeriesDesktop from "./TruckMountedSeriesDesktop";

const TruckMountedSeries = () => {
  const isMobile = useMediaQuery({ maxWidth: 768 });

  return isMobile ? (
    <TruckMountedSeriesMobile />
  ) : (
    <TruckMountedSeriesDesktop />
  );
};

export default TruckMountedSeries;
