import { useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import containerImg from "../../assets/images/home/container.jpg";
import container1 from "../../assets/images/container/1container.jpg";
import container2 from "../../assets/images/container/2container.jpg";
import container3 from "../../assets/images/container/3container.jpg";

import { ReactComponent as Technical } from "../../assets/images/container/ContainerSeries-01.svg";
import { ReactComponent as Measurements } from "../../assets/images/container/ContainerSeries-02.svg";

function ContainerSeries() {
  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 2000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 6000,
  };
  const [activeTab, setActiveTab] = useState("technical");

  return (
    <div className="product-page">
      {/* Banner Section */}
      <div className="product-banner-container">
        <div className="relative w-full h-[90vh] bg-black overflow-hidden">
          <img
            src={containerImg}
            alt="tanker"
            className="w-full h-[90vh] object-cover"
          />
          <div className="scroll-indicator-alt">
            <div className="mouse-icon-alt"></div>
            <p>Scroll Down</p>
          </div>
          <div className="absolute inset-0">
            <div className="absolute top-0 left-0 w-full h-1/4 bg-gradient-to-b from-black to-transparent"></div>
            <div className="absolute bottom-0 left-0 w-full h-1/4 bg-gradient-to-t from-black to-transparent"></div>
          </div>

          <div className="banner-header relative z-10 text-white text-center">
            <h1 className="md:text-5xl lg:text-6xl sm:text-3xl mt-12">
              Container Series
            </h1>
          </div>
        </div>
      </div>

      <div className="impact-resistance-section">
        {/* Sabit Metin */}
        <div className="text-section pt-20">
          <h2>Built for the Long Haul</h2>
          <p>
            Versatile and efficient, our container silos come in tank, V-type,
            W-type (multi-compartment), and cylindrical designs. Available in 20
            to 45 feet, they feature aluminum or steel connection fittings,
            ensuring reliable bulk material transport for various industries.
          </p>
        </div>

        {/* Slider Bölümü */}
        <div className="image-section mt-6">
          <Slider {...sliderSettings}>
            <div>
              <img
                src={container1}
                alt="Container 1"
                className="w-full rounded-lg"
              />
            </div>
            <div>
              <img
                src={container2}
                alt="Container 2"
                className="w-full rounded-lg"
              />
            </div>
            <div>
              <img
                src={container3}
                alt="Tipping Silo 3"
                className="w-full rounded-lg"
              />
            </div>
          </Slider>
        </div>
      </div>
      <div className="w-full max-w-4xl flex flex-col justify-start items-start mx-auto mt-12">
        <div className="flex justify-start gap-12 w-full mb-4 ml-4">
          <h3
            className={`cursor-pointer text-xl transition-all duration-300 ${
              activeTab === "technical"
                ? "text-white"
                : "text-gray-500 hover:text-gray-300"
            }`}
            onClick={() => setActiveTab("technical")}
          >
            Technical Parameters
          </h3>
          <h3
            className={`cursor-pointer text-xl transition-all duration-300 ${
              activeTab === "measurements"
                ? "text-white"
                : "text-gray-500 hover:text-gray-300"
            }`}
            onClick={() => setActiveTab("measurements")}
          >
            Measurements
          </h3>
        </div>

        {/* Görseller */}
        <div className="flex justify-start items-start w-full min-h-[500px] max-h-[500px] transition-all duration-500 ease-in-out">
          {activeTab === "technical" ? (
            <Technical
              alt="Technical Parameters"
              className="max-w-full max-h-[600px] object-contain my-svg"
            />
          ) : (
            <Measurements
              alt="Measurements"
              className="max-w-full max-h-[600px] object-contain my-svg"
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default ContainerSeries;
